(function () {
	'use strict';

angular.module('dashboard')

.controller('EditEventController', [ '$scope', '$uibModalInstance', 'modalParams', 'UsersService', 'EventsService', function($scope, $uibModalInstance, modalParams, UsersService, EventsService) {
	
	$scope.selected_collaborator = {};
	$scope.collaborators = [];
	$scope.date = null;

	$scope.init = function () {
		// UsersService.GetCollaborators().then(function success(response){
		// 	$scope.collaborators = response.data.result;
		// }, function error(response){
		// 	console.log('error fetching collaborators', response);
		// });
		$scope.event = angular.copy(modalParams.event); //clonamos el objeto
		$scope.date = moment($scope.event.eventTime);
	};

	$scope.confirm = function() {
		$scope.event.eventTime = moment($scope.date).format();
		EventsService.PatchEvent($scope.event).then(function success(response){
			modalParams.event = $scope.event;
			$uibModalInstance.close();
		}, function error(response) {
			console.log('error patch event', response);
		});
	};

	$scope.cancel = function() {
		$uibModalInstance.dismiss();
	};

	$scope.selectScore = function (score) {
		if($scope.event.score === score){
			$scope.event.score = '';
		}
		else {
			$scope.event.score = score;
		}
	}

	$scope.init();	
	
}]);

})();