'use strict';

angular.module('app.components', [  'login',
									'dashboard',
									'defaultInstance',
									'performance-evaluation',
									'log',
									'import-team',
									'employees',
									'employees-relationships',
									'evaluation-period',
									'reports',
									'my-collaborators',
									'new-employee']);