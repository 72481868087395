'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtcSecondStepController', ['$scope', '$stateParams', '$state', '$rootScope', '$uibModal', '$translate', 'PerformanceEvaluationService', '$localStorage', function ($scope, $stateParams, $state, $rootScope, $uibModal, $translate, PerformanceEvaluationService, $localStorage) {

		$scope.valuesDetails = {
			'Capacidad de aprendizaje e innovación': {
				/*  ver opciones */ 
				'Gran trabajo': '',
				'Buen camino': '',
				'A considerar': '',
				'A mejorar': ''
			},
			'Relaciones interpersonales': {
				'Gran trabajo': 'Desarrolla nuevos enfoques y mejora el estándar',
				'Buen camino': 'En muchas ocasiones aporta sugerencias valiosas para mejorar',
				'A considerar': 'Acompaña los cambios, pero no los lidera ni se involucra profundamente',
				'A mejorar': 'No desarrolla nuevos enfoques y le incomoda adaptarse a nuevas formas de hacer las cosas'
			},
			'Organización y planificación': {
				'Gran trabajo': 'Brinda soluciones prácticas, mejorando la experiencia de sus clientes',
				'Buen camino': 'Muestra preocupación respecto a cómo mejorar los procesos y simplificarlos',
				'A considerar': 'No propone soluciones, pero le interesa incorporarlas si alguien más lo sugiere',
				'A mejorar': 'Se atiene exclusivamente a los procesos existentes\nsin reflexionar respecto a cómo mejorar la experiencia del cliente'
			},
			'Flexibilidad': {
				'Gran trabajo': 'Está disponible, brindando trato personalizado',
				'Buen camino': 'Se muestra disponible en caso que alguien solicite proactivamente su atención',
				'A considerar': 'Su modo de contacto es predominantemente remoto. Actúa solo si es necesario',
				'A mejorar': 'No demuestra un interés específico en estar disponible para los demás'
			},
			'Comunicación efectiva': {
				'Gran trabajo': 'Asume la responsabilidad, empujando los temas hasta lograrlos',
				'Buen camino': 'Ejecuta sus tareas y frecuentemente se involucra\nen las acciones del equipo aunque esto implique ir más allá de su rol',
				'A considerar': 'Ejecuta sus tareas con responsabilidad,\npero ajustándose solamente a lo que está establecido en su descripción de rol',
				'A mejorar': 'Descarga tareas en el resto del equipo, sin involucrarse directamente con ninguna'
			}
		}

		$scope.init = function () {
			$scope.star = 0;
			$scope.selectedGP = undefined;

			$scope.loggedUsername = $scope.loggedUser.email;
			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}

			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				$scope.useObjectives = $scope.evaluation.nextObjectivesDto;
				$scope.vafs = $scope.removeRepeated(['valueName'], $scope.evaluation.valuesCalification);
				$scope.setStar();
				if ($rootScope.loggedUser.isLeader) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator
				} else {
					if ($rootScope.loggedUser.isCollaborator) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee
					}
				}

				$scope.isDisabled = true;
				
			}, function error(_error) {
				console.log('error fetching evaluation', $scope.evalId)
			})
		};

		$scope.save = function (evaluation) {
			$scope.recoverValueAspects();
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				$scope.evaluation.currentStepEvaluator = 2;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
					$scope.evaluation.currentStepEvaluatee = 2;
				}
			}
			PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
				function success(_response) {
					swal('Guardada', 'Evaluación guardada con éxito', 'success');
				}, function error(error) {
					if(error.data.cause === 'STEP_TWO_INCOMPLETE_FIELDS'){
						let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
						swal('Error al guardar la evaluación.', errorText, 'error');
						$scope.validationErrors = error.data.validationErrors;
					}else{
						swal('Error al guardar la evaluación', 'Error desconocido', 'error')
					}
				}
			);
		};

		$scope.continue_evaluation = function (id, evaluation) {
			$scope.recoverValueAspects();
			let modalTitle = 'Evaluación Completa';
			let modalDescription = '';
			let showRevision = false;

			$scope.submitted = true;

			if ($rootScope.loggedUser.isCollaborator) {
				modalDescription = `Gracias por completar la evaluación. Ahora ${$scope.evaluation.evaluatorDto.fullname} debe finalizar la evaluación.`;
			}
			if ($rootScope.loggedUser.isLeader && ($scope.evaluation.state === 'IN_PROGRESS_COLLAB' || $scope.evaluation.state === 'IN_PROGRESS')) {
				modalDescription = `Espere a que el Colaborador ${$scope.evaluation.evaluatedDto.fullname} termine de completar la evaluación para finalizarla.`;
			}
			if ($rootScope.loggedUser.isLeader && ($scope.evaluation.state === 'COMPLETED' || $scope.evaluation.state === 'IN_PROGRESS_LEAD')) {
				modalTitle = 'Evaluación finalizada';
				modalDescription = 'Evaluación finalizada por ambas partes. Continue a la revisión';
				showRevision = true;
			}

			if ($rootScope.loggedUser.isLeader) {
				$scope.evaluation.currentStepEvaluator = 2;
			} else {
				if ($rootScope.loggedUser.isCollaborator) {
					$scope.evaluation.currentStepEvaluatee = 2;
				}
			}

			if (showRevision) {
				PerformanceEvaluationService.PostPerformanceEvaluation(evaluation).then(
					function success(_response) {
						$state.go('ltc-third-step', { evaluationId: id });
					}, function error(error) {
						if(error.data.cause === 'STEP_TWO_INCOMPLETE_FIELDS'){
							let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
							swal('Error al guardar la evaluación.', errorText, 'error');
							$scope.validationErrors = error.data.validationErrors;
						}else{
							swal('Error al guardar la evaluación', 'Error desconocido', 'error')
						}
					}
				);
			}
			else {
				PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
					function success(_response) {
						swal({
							title: modalTitle,
							text: modalDescription,
							type: 'info',
							showCancelButton: true,
							confirmButtonClass: 'btn-info',
							confirmButtonText: 'Confirmar',
							cancelButtonText: 'Cancelar',
							closeOnConfirm: false,
							closeOnCancel: true,
						},function (isConfirm) {
							if (isConfirm) {
								swal('Evaluación guardada con éxito', '', 'success')
								$state.go('dashboard');
							}
						});
					}, function error(error) {
						if(error.data.cause === 'STEP_TWO_INCOMPLETE_FIELDS'){
							let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
							swal('Error al guardar la evaluación.', errorText, 'error');
							$scope.validationErrors = error.data.validationErrors;
						}else{
							swal('Error al guardar la evaluación', 'Error desconocido', 'error')
						}
					}
				);
			}
		};

		$scope.removeRepeated = function (repeatKeys, list) {
			let firstValue = '';
			const result = list.map(x => Object.assign({}, x));
			repeatKeys.forEach(key => {
				result.forEach((obj, index) => {
					obj['original'] = list[index];
					if(key == repeatKeys[0]){
						obj['group'] = obj[repeatKeys[0]];
					}
					if (firstValue != obj[key]) {
						firstValue = obj[key];
					} else {
						obj[key] = '';
					}
				})
			});
			return result;
		}
	
		$scope.recoverValueAspects = function () {
			$scope.evaluation.valuesCalification = $scope.vafs.map(x => x.original);
		}
	
		$scope.back = function (id, _evaluation) {
			$state.go('ltc-first-step', { evaluationId: id });
		}

		$scope.newOkr = function () {
			let emptyOkr = {
				'description': '',
				'complianceDegree': 0,
				'keyResults': []
			};
			$scope.useObjectives.push(emptyOkr);
		}

		$scope.newKeyResult = function (index) {
			let emptyKeyResult = {
				'description': '',
				'calification': 0
			}
			$scope.useObjectives[index].keyResults.push(emptyKeyResult);
		}

		$scope.deleteObjective = function (index) {
			$scope.useObjectives.splice(index, 1);
		}

		$scope.deleteKeyResult = function (index, keyResultIndex) {
			$scope.useObjectives[index].keyResults.splice(keyResultIndex, 1);
		}

		$scope.selectMood = function (mood) {
			if ($scope.evaluation.mood === mood) {
				$scope.evaluation.mood = undefined;
			}
			else {
				$scope.evaluation.mood = mood;
			}
		}

		$scope.selectGeneralPerformance = function (gp) {
			if ($scope.evaluation.generalPerformance === gp) {
				$scope.evaluation.generalPerformance = undefined;
			} else {
				$scope.evaluation.generalPerformance = gp;
			}
		}

		$scope.selectGeneralPerformance = function () {
				$scope.mandatory
			switch ($scope.star) {
				case 1:
					$scope.evaluation.generalPerformance = 'No alcanza las expectativas';
					$scope.selectedGP = 1;
					break;
				case 2:
					$scope.evaluation.generalPerformance = 'Por debajo de las expectativas';
					$scope.selectedGP = 2;
					break;
				case 3:
					$scope.evaluation.generalPerformance = 'Alcanza las expectativas';
					$scope.selectedGP = 3;
					break;
				case 4:
					$scope.evaluation.generalPerformance = 'Supera las expectativas';
					$scope.selectedGP = 4;
					break;
			}
			$scope.oldSelectedGP = $scope.selectedGP;
		}

		$scope.setStar = function () {
			if ($scope.evaluation.generalPerformance != '') {
				switch ($scope.evaluation.generalPerformance) {
					case 'No alcanza las expectativas':
						$scope.selectedGP = 1;
						break;
					case 'Por debajo de las expectativas':
						$scope.selectedGP = 2;
						break;
					case 'Alcanza las expectativas':
						$scope.selectedGP = 3;
						break;
					case 'Supera las expectativas':
						$scope.selectedGP = 4;
						break;
				}
			}
		}

		$scope.collaborator_can_see = function (evaluationState) {
			return ($localStorage.loggedUser.isLeader) || 
			($localStorage.loggedUser.isCollaborator && ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes(evaluationState))
		}

		$scope.mouseOver = function (starNumber) {
			if ($scope.loggedUserRoleInEval === 'EVALUATOR') {
				$scope.oldSelectedGP = $scope.selectedGP;
				$scope.selectedGP = starNumber;
				$scope.star = starNumber;
			}
		}
		$scope.mouseLeave = function (starNumber) {
			if ($scope.loggedUserRoleInEval === 'EVALUATOR') {
				$scope.selectedGP = $scope.oldSelectedGP;
				$scope.star = 0;
			}
		}


		$scope.init();

	}]);
