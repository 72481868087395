'use strict';

angular.module('app.layout', [ 'ngSanitize', 'translate', 'app.config' ])

/* Setup global settings */
.factory('settings', ['$rootScope', '$window', function($rootScope, $window) {
    // supported languages
    var settings = {
        layout: {
            pageSidebarClosed: false, // sidebar menu state
            pageContentWhite: false, // set page content layout
            pageBodySolid: true, // solid body color state
            pageAutoScrollOnLoad: 1000 // auto scroll to top on page load
        },
        assetsPath: 'assets',
        globalPath: 'assets/global',
        layoutPath: 'assets/layouts/layout',
    };

    $rootScope.settings = settings;

    return settings;
}])



/* Setup App Main Controller */
.controller('LayoutController', ['$scope', '$rootScope', 'AuthenticationService', 'SidebarService', 'toaster', function($scope, $rootScope, AuthenticationService, SidebarService, toaster) {
    $scope.$on('$viewContentLoaded', function() {
//        App.initComponents(); // init core components
//        Layout.init(); //  Init entire layout(header, footer, sidebar, etc) on page load if the partials included in server side instead of loading with ng-include directive
    	// initialize core components
//    	App.initAjax();
    	ComponentsDateTimePickers.init();
    });
    
    if ($rootScope.menu === undefined) {
  		$rootScope.menu = {};
  	}
    
  	$scope.menu = $rootScope.menu;
    
    $rootScope.loadMenu = function() {
      $rootScope.menu.menu = {};
      SidebarService.menu().then(function success(response) {
        $scope.menu = response.data;
      }, function error(response){
      });
    };
    
    $rootScope.loadMenu();

    $rootScope.underConstructionPage = function(text) {
        toaster.info(text);
    };
}])

/* Init global settings and run the app */
.run(['$rootScope', 'settings', function($rootScope, settings) {
    $rootScope.$settings = settings; // state to be accessed from view
}]);
