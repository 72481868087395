(function () {
  'use strict';

  angular.module('employees-relationships')

    .controller('NewRelationshipController', ['$state', '$scope', '$timeout', 'UsersService', '$translate', function ($state, $scope, $timeout, UsersService, $translate) {


      $scope.init = function () {
        // Variables de los filtros.
        $scope.fullname = null;
        $scope.management = null;
        $scope.employeeId = null;
        $scope.subsidiary = null;

        $scope.selectedCollaborators = [];

        $scope.currentStep = 1;
        $scope.selected_leader = null;
        $scope.leader = null;
        $scope.selected = null;

      };

      $scope.makeFilter = function () {
        let filter = {};

        if ($scope.fullname !== null && $scope.fullname !== '') {
          filter['fullname'] = $scope.fullname;
        }
        if ($scope.management !== null && $scope.management !== '') {
          filter['management'] = $scope.management;
        }
        if ($scope.employeeId !== null && $scope.employeeId !== '') {
          filter['employeeId'] = $scope.employeeId;
        }
        if($scope.subsidiary !== null && $scope.subsidiary !== '') {
          filter['subsidiary'] = $scope.subsidiary;
        }

        var pageable = {};
        pageable['page'] = 1;
        pageable['limit'] = 10;
        filter.pageable = pageable;

        return filter;
      }


      $scope.getLeaders = function (currentPage) {
        let filter = $scope.makeFilter();
        filter.pageable.page = currentPage;
        UsersService.GetLeaders(filter).then(function (success) {
          $timeout(function () { $scope.$broadcast('loadPagination', success.data.paging); });
          $scope.leaders = success.data.result;
          $scope.leaders = $scope.leaders.filter((e) => e.endDate === null);
        }, function (error) {
          console.log('error fetching leaders', error);
          swal('No es posible recuperar información de los líderes', error.data.cause, 'error');
        })
      }

      $scope.getEmployees = function (currentPage) {
        let filter = $scope.makeFilter();
        filter.pageable.page = currentPage;
        UsersService.GetEmployees(filter).then(function (success) {
          $timeout(function () { $scope.$broadcast('loadPagination', success.data.paging); });
          $scope.employees = success.data.result;
          $scope.employees = $scope.employees.filter((e) => e.endDate === null);
          $scope.employees.map((e) => { return e.selected = false });
        }, function (error) {
          console.log('error fetching employees', error);
          swal('No es posible recuperar información de los colaboradores', error.data.cause, 'error');
        })
      }

      $scope.selectLeader = function (leaderIndex, leader) {
        $scope.leader = leader;
      }

      $scope.selectCollaborator = function (employee) {
        if(!$scope.selectedCollaborators.some((e) => e.employeeId === employee.employeeId)){
          $scope.selectedCollaborators.push(employee);
        }
      }

      $scope.deselectCollaborator = function (employeeId) {
        $scope.selectedCollaborators = $scope.selectedCollaborators.filter((e) => e.employeeId != employeeId);
      }

      $scope.cleanFilter = function () {
        $scope.fullname = null;
        $scope.management = null;
        $scope.employeeId = null;
        $scope.subsidiary = null;
      }

      $scope.back = function () {
        if ($scope.currentStep === 1) {
          $state.go('assignments')
        } else {
          $scope.currentStep--;
        }
      }

      $scope.continue = function () {
        $scope.cleanFilter();
        $scope.currentStep = $scope.currentStep + 1;
      }

      $scope.finish = function () {
        swal({
          title: 'Se asignará a ' + $scope.leader.fullname + ' los nuevos colaboradores seleccionados.',
          text: '¿Está seguro que desea continuar?',
          type: 'info',
          showCancelButton: true,
          confirmButtonClass: 'btn-info',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          closeOnConfirm: false,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            let body = {
              leaderId: $scope.leader.employeeId,
              collaboratorsId: $scope.selectedCollaborators.map((e) => e.employeeId)
            };
            UsersService.NewAssignment(body).then(function (success) {
              console.log('success assign employees');
              swal('Asignados', 'Colaboradores asignados exitosamente', 'success');
              $state.go('assignments');
            }, function (response) {
              var errorDetails = $translate.instant('load.ERRORS.' + response.data.errorCode);
              swal('Error al intentar realizar la asignación de colaboradores', errorDetails, 'error');
            })
          }
        })
      };

      $scope.init();

    }])
})();