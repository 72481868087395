angular.module('gdhBarChart', [])

    .directive('gdhBarChart', function () {
        return {
        restrict: 'E',
        scope: {
            id: '@',
            data: '=',
            categoryTitle: '@',
            valueTitle: '@'
        },
        replace: true,
        link: function ($scope) {

            //ver tutorial: https://www.amcharts.com/docs/v4/chart-types/xy-chart/

            // data = [
            //     {
            //         category: 'Happy',
            //         value: 10
            //     },
            //     {
            //         category: 'Mad',
            //         value: 5
            //     }
            // ]
            let id = $scope.id;

            am4core.useTheme(am4themes_animated);


            // PASO 1 Creacion del chart
            var chart = am4core.create(id, am4charts.XYChart);
            
            var title = chart.titles.create();
            title.text = id;
            title.fontSize = 14;
            title.marginBottom = 2;
            title.align = 'left';
            title.color = am4core.color('#888a88');

            // PASO 2 Setear la data
            chart.data = $scope.data;
            chart.dataSource.reloadFrequency = 1;
            chart.cursor = new am4charts.XYCursor();

            chart.cursor.lineY.disabled = true;
            chart.cursor.lineX.disabled = true;
            


            // PASO 3 Configurar los Axes
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            // categoryAxis.renderer.grid.template.location = 0.5;
            // categoryAxis.renderer.labels.template.location = 0.5;
            categoryAxis.autoGridCount = false;
            categoryAxis.gridCount = 4;
            categoryAxis.dataFields.category = 'category'
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.title = $scope.categoryTitle
            categoryAxis.renderer.labels.template.adapter.add('text', function(text, target) {
                return '';
              });


            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title = $scope.valueTitle
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            // valueAxis.disabled=true;
            valueAxis.renderer.labels.template.adapter.add('text', function(text, target) {
                return '';
              });
            valueAxis.cursorTooltipEnabled = false;

            chart.colors.list = [
                am4core.color('#ff0000'),
                am4core.color('#ffbf00'),
                am4core.color('#91cf4f'),
                am4core.color('#00af4f'),
              ];
            
            // PASO 4 Configurar el Series
            let serie = new am4charts.ColumnSeries();
            var series = chart.series.push(serie);
            
         

            series.columns.template.events.once('inited', function(event){
                event.target.fill = chart.colors.getIndex(event.target.dataItem.index);
              });
            series.strokeWidth = 0;
            series.calculatePercent = true;

            
            series.dataFields.categoryX = 'category';
            series.dataFields.valueY = 'value';

            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = '{valueY.percent.formatNumber("#,###.0")}%';
            valueLabel.dy = -15;
            valueLabel.label.fontSize = 13;
     
        
            $scope.$on('$destroy', function () {
                chart.dispose();

            });
        }
    };
});

