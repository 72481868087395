'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlFirstStepController', ['$scope', '$stateParams', '$state', '$rootScope', '$translate', 'PerformanceEvaluationService', '$localStorage', function ($scope, $stateParams, $state, $rootScope, $translate, PerformanceEvaluationService, $localStorage) {

		$scope.init = function () {
			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}
			$scope.currentState = $state;
			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				// console.log(JSON.stringify(response.data.valuesCalification, undefined, 2))
				$scope.evaluation = response.data
				$scope.useObjectives = $scope.evaluation.objectivesDto;
				$scope.loggedUsername = $scope.loggedUser.email;
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee
					}
				}
			}, function error(_error) {
				console.log('error fetching evaluation', id)
			})
		};

		$scope.save = function (evaluation) {
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				$scope.evaluation.currentStepEvaluator = 1;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
					$scope.evaluation.currentStepEvaluatee = 1;
				}
			}
			PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
				function success(_response) {
					swal('Guardada', 'Evaluación guardada con éxito', 'success');
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					console.log('failure in continue evaluation-1');
				}
			);
		};


		$scope.continue_evaluation = function (evaluation) {

			$scope.submitted = true;
			
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				$scope.evaluation.currentStepEvaluator = 1;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
					$scope.evaluation.currentStepEvaluatee = 1;
				}
			}
			PerformanceEvaluationService.PostPerformanceEvaluation(evaluation).then(
				function success(_response) {
					$state.go('ltl-second-step', { evaluationId: evaluation.id });
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					$scope.validationErrors = error.data.validationErrors;
					console.log('failure in continue evaluation-1');
				}
			);

		};

		$scope.back = function (_id, _evaluation) {
			// TODO ask to POST evaluation ?
			$state.go('performance-evaluation');
		}

		$scope.newOkr = function () {

			let emptyOkr = {
				'description': '',
				'complianceDegree': 0,
				'keyResults': []
			};
			$scope.useObjectives.push(emptyOkr);
		}

		$scope.newKeyResult = function (index) {

			let emptyKeyResult = {
				'description': '',
				'calification': 0
			}
			$scope.useObjectives[index].keyResults.push(emptyKeyResult);
		}

		$scope.collaborator_can_see = function (evaluation) {
			return ($scope.loggedUserRoleInEval === 'EVALUATOR')
			|| (($scope.loggedUserRoleInEval === 'EVALUATEE') 
			&& ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes($scope.evaluation.state));
		}

		$scope.deleteObjective = function (index) {
			$scope.useObjectives.splice(index, 1);
		}

		$scope.deleteKeyResult = function (index, keyResultIndex) {
			$scope.useObjectives[index].keyResults.splice(keyResultIndex, 1);
		}


		$scope.init();

	}]);