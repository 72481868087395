'use strict';

angular.module('reports', ['ui.bootstrap'])

  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider.state('reports', {
      url: '/reports',
      templateUrl: 'components/reports/reports.html',
      controller: 'ReportsController'
    })

  }])

  .service('ReportService', [
    '$rootScope',
    '$http',
    'restApiUrl',
    function ($rootScope, $http, restApiUrl) {
      var service = {};
      service.Export = Export;
      function Export(period, type, role) {
        debugger
        return $http({
          url: restApiUrl + '/reports?periodId=' + period + '&type=' + type + '&role=' + role,
          method: 'GET',
          responseType: 'arraybuffer',
          headers: {
            'Content-type': 'application/json'
          }
        });
      }

      return service;
    }
  ])

  .controller('ReportsController', ['$scope', 'PeriodService', 'ReportService', function ($scope, PeriodService, ReportService) {

    $scope.selected_period = null;
    $scope.selected_type = null;

    $scope.types = [
      'PLANNING_REPORT',
      'EVALUATION_REPORT'
    ]
  
    $scope.init = function () {
      $scope.getPeriods();
    };

    $scope.getPeriods = function () {
      PeriodService.GetPeriods({}).then(function success(response) {
        $scope.periods = response.data.result;
      }, function error(response) {
        console.error('### error fetching periods ', response);
      });
    }

    var getFileNameFromResponse = function (response) {
      var contentDispositionHeader = response.headers('Content-Disposition');
      var result = contentDispositionHeader.split('filename=');
      return result[2].replace(/"/g, '');
    };

    $scope.tempExport = () => swal('No implementado.', JSON.parse($scope.selected_period).title, 'warning');
    $scope.export = function (role) {
      // //Completar
      // var reportDto = { 
      //       param1: //agregar
      //                 };
      $scope.admin_report = $scope.AdminReport ? 'HACIENDOBIND_ADMIN' : 'HACIENDOBIND_LIDER';
      ReportService.Export(JSON.parse($scope.selected_period).id, $scope.selected_type, $scope.admin_report).then(function (data) {
        var file = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var filename = getFileNameFromResponse(data);
        saveAs(file, filename);
        swal('Reporte generado correctamente', null, 'success');
        $scope.init();
      }, function (error) {
        swal('Error al generar el reporte.', error.data.cause, 'error');
      });
    };

    $scope.init();
  }])