'use strict';

angular.module('login', ['translate', 'app.config', 'ui.router', 'ngResource'])

.config([ '$stateProvider', '$urlRouterProvider',
	function($stateProvider) {                
        $stateProvider.state('login', {
            url: '/login',
            views: {
                'loginView': {
                    templateUrl: 'components/login/login.html',
                    controller: 'LoginController'                
                }
            }
		});
}])

.controller('LoginController', [
	'$scope',
    '$auth',
    '$localStorage',
    '$location',
	function($scope, $auth, $localStorage, $location) {

		$scope.authenticate = function(provider) {
			$auth.authenticate(provider).then(function (response) {
                console.debug('logged in');

                //Store loggedUser data
                $localStorage.loggedUser = {
                    'username': $auth.getPayload().sub,
                    'name': $auth.getPayload().name,
                    'picture': $auth.getPayload().picture,
                    'email': $auth.getPayload().email,
                    'authorities': $auth.getPayload().authorities
                };

                //Store roles flag
                $localStorage.loggedUser.isLeader = HasRole('HACIENDOBIND_LIDER'),
                $localStorage.loggedUser.isCollaborator = HasRole('HACIENDOBIND_COLABORADOR'),
                $localStorage.loggedUser.isAdmin =  HasRole('HACIENDOBIND_ADMIN')

                $location.path('/dashboard');

            }).catch(function (response) {
                console.log('Error during authentication...');
                $location.path('/login');
            });
        };

		$scope.isAuthenticated = function() {
			return $auth.isAuthenticated();
        };

        function HasRole(role){
            var authorities = $localStorage.loggedUser.authorities;
            for (let index = 0; index < authorities.length; index++) {
                const element = authorities[index];
                if(element === role){
                    return true;
                }
            }
            return false;
        }        
    }]);
    
    