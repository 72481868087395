(function () {
    'use strict';

    angular.module('events', [])


        /**
         *  
         * Notifications Service 
         * 
         * 
         */
        .service('EventsService', ['$http', 'restApiUrl',

            function ($http, restApiUrl) {


                var service = {};

                service.GetEvents = GetEvents;
                service.GetAllEvents = GetAllEvents;
                service.GetSubEvents = GetSubEvents;
                service.GetFeedbackEvents = GetFeedbackEvents;
                service.PostLeaderEvent = PostLeaderEvent;
                service.PostCollabEvent = PostCollabEvent;
                service.PostCollabOrMyselfEvent = PostCollabOrMyselfEvent;
                service.PatchEvent = PatchEvent;
                service.DeleteEvent = DeleteEvent;
                service.SetAgreed = SetAgreed;
                service.SetDisagreed = SetDisagreed;
                service.SetShared = SetShared;
                service.SetUnshared = SetUnshared;

                function GetEvents(params) {
                    let parsedParams = Object.keys(params).reduce((xs, x) => `${x}=${params[x]}&${xs}`, '');
                    let uri = `${restApiUrl}/events?${parsedParams}`;
                    return $http.get(uri);
                }

                function GetAllEvents(params) {
                    let parsedParams = Object.keys(params).reduce((xs, x) => `${x}=${params[x]}&${xs}`, '');
                    let uri = `${restApiUrl}/events/all?${parsedParams}`;
                    return $http.get(uri);
                }

                function GetSubEvents(params) {
                    let parsedParams = Object.keys(params).reduce((xs, x) => `${x}=${params[x]}&${xs}`, '');
                    let uri = `${restApiUrl}/events/subevents?${parsedParams}`;
                    return $http.get(uri);
                }

                function GetFeedbackEvents(params) {
                    let parsedParams = Object.keys(params).reduce((xs, x) => `${x}=${params[x]}&${xs}`, '');
                    let uri = `${restApiUrl}/events/leader?${parsedParams}`;
                    return $http.get(uri);
                }

                function PostLeaderEvent(body) {
                    return $http.post(`${restApiUrl}/events`, body);
                }

                function PostCollabEvent(body) {
                    return $http.post(`${restApiUrl}/events/leader`, body);
                }

                function PostCollabOrMyselfEvent(body, commentTo) {
                    if(commentTo === 'MYSELF') {
                        return $http.post(`${restApiUrl}/events/myself`, body);
                    }
                    return $http.post(`${restApiUrl}/events/leader`, body);
                }

                function PatchEvent(body) {
                    return $http.post(`${restApiUrl}/events/update`, body);
                }

                function DeleteEvent(event_id) {
                    return $http.delete(`${restApiUrl}/events/${event_id}`);
                }

                function SetAgreed(event_id) {
                    return $http.post(`${restApiUrl}/events/${event_id}/agreed`);
                }
                function SetDisagreed(event_id) {
                    return $http.post(`${restApiUrl}/events/${event_id}/disagreed`);
                }
                function SetShared(event_id) {
                    return $http.post(`${restApiUrl}/events/${event_id}/shared`);
                }
                function SetUnshared(event_id) {
                    return $http.post(`${restApiUrl}/events/${event_id}/unshared`);
                }

                return service;

            }]);

})();