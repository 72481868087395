(function () {
  'use strict';

angular.module('employees')

.controller('EmployeeInfoController', [ '$scope', '$uibModalInstance', 'modalParams', '$timeout', 'UsersService', 'EventsService', function($scope, $uibModalInstance, modalParams, $timeout, UsersService, EventsService) {

$scope.init = function () {
  $scope.employee = modalParams.employee;
  $scope.disableEdit = modalParams.disableEdit;
};

$scope.close = function() {
  $uibModalInstance.dismiss();
};

$scope.goEditEmployee = function (employee) {
  $uibModalInstance.close('edit');
}

$scope.init();	

}]);

})();