'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlFourthStepController', ['$scope', '$stateParams', '$state', '$rootScope', '$uibModal', 'PerformanceEvaluationService', '$localStorage', function ($scope, $stateParams, $state, $rootScope, $uibModal, PerformanceEvaluationService, $localStorage) {
		$scope.loggedUsername = $scope.loggedUser.email;
		$scope.evaluation = null;
		$scope.disabled = $stateParams.disabled;
		$scope.adminWatching = $stateParams.adminWatching;


		$scope.init = function () {
			$scope.incompleteFields = [];
			$scope.requiredFields = [
				{field: 'individualContributions', label: 'Principales contribuciones individuales.'}, 
				{field: 'collaboratorComments', label: 'Comentarios del colaborador'},
				{field: 'mainTestimoniesOustandingAttitudes', label: 'Principales testimonios. Actitudes destacadas y aspectos a mejorar.'},
				{field: 'learningsAndReflexions', label: 'Aprendizajes y reflexiones.'},
				{field: 'aliderAutoevaluation', label: 'Autoevaluación como líder. ¿Cómo te acompañé en este período?'},
				{field: 'accompaniedCollaboratorFelt', label: 'Evaluación como colaborador. ¿Cómo me sentí acompañado en este período?'},
				{field: 'generalPerformance', label: 'Puntuación general de desempeño. Marque el resultado que mejor refleje el desempeño general.'},
				{field: 'mood', label: '¿Cómo estoy ahora como colaborador?'}
			];

			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}
			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				$scope.evaluation.valuesCalification.sort((x, y) => $scope.compareValueName(x.valueName, y.valueName));
				$scope.loggedUsername = $scope.loggedUser.email;
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee
					}
				}
				$scope.getEmptyFields();
			}, function error(_error) {
				console.log('error fetching evaluation', id)
			})

		};

		$scope.compareValueName = function (a, b) {
			if (a.valueName == b.valueName) {
				return $scope.compareAspectName(a, b);
			}
			if (a.valueName < b.valueName) {
				return -1;
			}
			if (a.valueName > b.valueName) {
				return 1;
			}
		}

		$scope.compareAspectName = function (a, b) {
			if (a.aspectName < b.aspectName) {
				return -1;
			}
			if (a.aspectName > b.aspectName) {
				return 1;
			}
			return 0;
		}

		$scope.isEvaluator = function () {
			return $scope.loggedUsername === $scope.evaluation.evaluatorDto.email
		}

		$scope.confirm_evaluation = function (evaluation) {
			let modalTitle = '';
			let modalDescription = '';
			let doComplete = undefined;

			if ($scope.evaluation.evaluatedDto.email === $scope.loggedUsername) {
				modalTitle = 'Evaluación completa';
				modalDescription = `Gracias por completar la evaluación. Ahora ${$scope.evaluation.evaluatorDto.fullname} debe finalizar la evaluación.`;
				doComplete = false;
			}
			if ($scope.evaluation.evaluatorDto.email === $scope.loggedUsername && $scope.evaluation.state === 'IN_PROGRESS_COLLAB') {
				modalTitle = 'Evaluación completa';
				modalDescription = `Espere a que el Colaborador ${$scope.evaluation.evaluatedDto.fullname} termine de completar la evaluación para finalizarla.`;
				doComplete = false;
			}
			if ($scope.evaluation.evaluatorDto.email === $scope.loggedUsername && $scope.evaluation.state === 'COMPLETED') {
				modalTitle = 'Finalizar evaluación';
				modalDescription = 'Evaluación completada por ambas partes. ¿Desea finalizarla?';
				doComplete = true;
			}

			swal({
				title: modalTitle,
				text: modalDescription,
				type: 'info',
				showCancelButton: true,
				confirmButtonClass: 'btn-info',
				confirmButtonText: 'Confirmar',
				cancelButtonText: 'Cancelar',
				closeOnConfirm: false,
				closeOnCancel: true,
			},
				function (isConfirm) {
					if (isConfirm) {
						if (doComplete) {
							PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
								(success) => {
									swal('¡Evaluación completada con éxito!', '', 'success');
									$scope.goToConditional();
								},
								(error) => {
									if(error.data.cause !== undefined){
										let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
										swal('Error al guardar la evaluación.', errorText, 'error');
										$scope.validationErrors = error.data.validationErrors;
									}else{
										swal('Error al guardar la evaluación', 'Error desconocido', 'error')
									}
									console.log('failure in continue evaluation-1', error);
								})
						}
						else {
							$state.go('dashboard')
						}
					}
				});
		};

		$scope.getEmptyFields = function () {		
			$scope.requiredFields.forEach(function (x) {
				 if (!$scope.evaluation[x.field]){
					$scope.incompleteFields.push(x);
				 }
			});
		}

		$scope.collaborator_can_see = function (evaluationState) {
			var validAdmin = $localStorage.loggedUser.isAdmin;
			var validLider = $localStorage.loggedUser.isLeader && $scope.loggedUserRoleInEval === 'EVALUATOR';
			var validColab = $localStorage.loggedUser.isCollaborator && $scope.loggedUserRoleInEval === 'EVALUATEE' && ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes(evaluationState);
			return (validAdmin || validLider || validColab);
		}

		$scope.goToConditional = function () {
			let isEvaluator = $scope.isEvaluator();
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else {
				$state.go('my-evaluation');
			}
		}

		$scope.back = function (id, _evaluation) {
			let isEvaluator = $scope.isEvaluator();
			if ($scope.disabled && isEvaluator) {
				$state.go('performance-evaluation');
			} else if ($scope.disabled && !(isEvaluator)) {
				$state.go('my-evaluation');
			}
			else {
				$state.go('ltl-third-step', { evaluationId: id });
			}
		}

		$scope.close = function (_id, _evaluation) {
			let isEvaluator = $scope.isEvaluator();
			console.log('asdf', $scope.adminWatching);
			if ($scope.adminWatching) {
				$state.go('search-evaluation');
				return;
			}
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else if (!(isEvaluator)) {
				$state.go('my-evaluation');
			}
		}

		$scope.init();

	}]);