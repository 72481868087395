(function () {
    'use strict';

    angular.module('periods', [])


        /**
         *  
         * Periods Service 
         * 
         * 
         */
        .service('PeriodService', ['$http', 'restApiUrl',

            function ($http, restApiUrl) {


                var service = {};

                service.GetPeriods = GetPeriods;
                service.PostPeriod = PostPeriod;
                service.DeletePeriod = DeletePeriod;
                service.UpdatePeriod = UpdatePeriod;
                service.ExtendPeriod = ExtendPeriod;

                function GetPeriods(params) {
                    let parsedParams = Object.keys(params).reduce((xs, x) => `${x}=${params[x]}&${xs}`, '');
                    let uri = `${restApiUrl}/periods?${parsedParams}`;
                    return $http.get(uri);
                }

                function PostPeriod(period) {
                    return $http.post(`${restApiUrl}/periods`, period);
                }

                function DeletePeriod(period_id) {
                    return $http.delete(`${restApiUrl}/periods/${period_id}`);
                }

                function UpdatePeriod(currentPeriod) {
                    return $http.put(`${restApiUrl}/periods`, currentPeriod);
                }

                function ExtendPeriod(currentPeriod) {
                    return $http.put(`${restApiUrl}/periods/extend-period`, currentPeriod);
                }

                return service;


            }]);

})();