'use strict';

angular.module('new-employee', ['paginationModule'])

  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider.state('new-employee', {
      url: '/new-employee',
      templateUrl: 'components/employees/new-employee.html',
      controller: 'NewEmployeeController'
    })

  }])


  .controller('NewEmployeeController', ['$scope', 'UsersService', 'restApiUrl', '$rootScope', '$timeout', '$state', '$http', '$uibModal', '$translate',
    function ($scope, UsersService, restApiUrl, $rootScope, $timeout, $state, $http, $uibModal, $translate) {
    $scope.init = function () {
      // Variables de los filtros.
      $scope.fullname = null;
      $scope.management = null;
      $scope.employeeId = null;

      $scope.role = 'ROLE_COLLABORATOR';
      $scope.newEmployee = {
        fullname: undefined,
        email: undefined,
        dni: undefined,
        employeeId: undefined,
        company: undefined,
        management: undefined,
        sector: undefined,
        subsidiary: undefined,
        category: undefined,
        position: undefined,
        agreement: undefined,
        startDate: undefined,
        birthday: undefined,
        age: undefined,
        address: undefined,
        city: undefined,
        province: undefined,
        project: undefined,
        genre: undefined
      };

      $scope.newEmployee.startDate = (new Date()).toLocaleDateString('en-GB');

      $scope.selected_leader = null;
      $scope.leader = null;

      $scope.selected_collaborators = [];
      $scope.collaborators = [];

      $scope.currentStep = 1;
    }

    $scope.makeFilter = function () {
      let filter = {};
      if ($scope.fullname !== null && $scope.fullname !== '') {
        filter['fullname'] = $scope.fullname;
      }
      if ($scope.management !== null && $scope.management !== '') {
        filter['management'] = $scope.management;
      }
      if ($scope.employeeId !== null && $scope.employeeId !== '') {
        filter['employeeId'] = $scope.employeeId;
      }

      var pageable = {};
      pageable['page'] = 1;
      pageable['limit'] = 10;
      filter.pageable = pageable;

      return filter;
    }

    $scope.getLeaders = function (currentPage) {
      let filter = $scope.makeFilter();
      filter.pageable.page = currentPage;
      UsersService.GetLeaders(filter).then(function (success) {
        $timeout(function () { $scope.$broadcast('loadPagination', success.data.paging); });
        $scope.employees = success.data.result;
      }, function (error) {
        console.log('error fetching employees ', error);
        swal('No ha sido posible recuperar los colaboradores', error.data.cause, 'error');
      })
    }


    $scope.selectLeader = function (leaderIndex, leader) {
      $scope.leader = leader;
    }

    $scope.selectCollab = function (employeeIndex, employee) {
      if ($scope.selected_collaborators[employeeIndex] !== undefined) {
        $scope.selected_collaborators[employeeIndex] = undefined
      } else {
        $scope.selected_collaborators[employeeIndex] = employee;
      }
      // $scope.selected_collaborators = $scope.selected_collaborators.filter((x) => x != null);
    }

    $scope.isSelected = function (employeeIndex, employee) {
      return $scope.selected_collaborators[employeeIndex] === employee;
    }

    $scope.cleanFilter = function () {
      $scope.fullname = null;
      $scope.management = null;
      $scope.employeeId = null;
    }

    $scope.continue = function () {
      $scope.employees = null;
      $scope.currentStep++;
    }

    $scope.back = function () {
      if ($scope.currentStep === 1) {
        $state.go('search-employee');
      } else {
        $scope.currentStep--;
      }
    }

    $scope.getAge = function (dob) {
      var today = new Date();
      var x = dob;
      var birthDate = moment(dob, 'DD/MM/YYYY').toDate();;
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }
      $scope.newEmployee.age = age;
    }

    $scope.changeRole = function () {
      if($scope.role === 'ROLE_COLLABORATOR') {
        $scope.role = 'ROLE_LEAD';
      }
      else {
        $scope.role = 'ROLE_COLLABORATOR';
      }
    }

    $scope.check = function () {
      return Object.keys($scope.newEmployee).reduce((accum, current) => { return (accum && $scope.newEmployee[current] != undefined && $scope.newEmployee[current] != '') }, true);
    }

    $scope.finish = function () {
      if (!($scope.check())) {
        swal('Error en la validación del formulario', 'Revise los campos', 'error');
      } else {
        
        swal({
          title: 'Nuevo colaborador',
          text: 'Se creará el usuario ' + $scope.newEmployee.fullname + ' - ' + $scope.newEmployee.email + ' con ' + $scope.leader.fullname + ' como líder.',
          type: 'info',
          showCancelButton: true,
          confirmButtonClass: 'btn-info',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          closeOnConfirm: false,
          closeOnCancel: true,
        },
          function (isConfirm) {
            if (isConfirm) {
              let employeeToAdd = angular.copy($scope.newEmployee);
              employeeToAdd.startDate = moment(employeeToAdd.startDate, 'DD/MM/YYYY').format();
              employeeToAdd.birthday = moment(employeeToAdd.birthday, 'DD/MM/YYYY').format();
              let body = {
                role: $scope.role,
                leader: $scope.leader.employeeId,
                details: employeeToAdd
              };
              UsersService.CreateEmployee(body).then(function (success) {
                swal('Creado', 'Nuevo colaborador creado exitosamente', 'success');
                $scope.init();
              },
                function (error) {
                    if (error.data.validationErrors && error.data.validationErrors.length > 0) {
                        var errorDetails = $translate.instant('load.ERRORS.' + error.data.validationErrors[0].message);
                        for (var i = 1; i < error.data.validationErrors.length-1; i++) {
                            errorDetails = errorDetails + '\n' + $translate.instant('load.ERRORS.' + error.data.validationErrors[i].message);
                        }
                        swal($translate.instant('load.ERRORS.' + error.data.cause), errorDetails, 'error');
                    } else {
                        swal('No ha sido posible crear el colaborador',$translate.instant('load.ERRORS.' + error.data.cause), 'error');
                    }
                })
            }
          });
      }
    }

    $scope.$watch('newEmployee', function (newValue, _oldValue) {
      if (newValue.birthday != undefined) {
        $scope.getAge($scope.newEmployee.birthday);
      }
    }, true)

    $scope.init();
  }]);