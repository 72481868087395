(function () {
    'use strict';

angular.module('performance-evaluation')

.controller('ConfirmPerformanceEvaluationCtrl', [ '$scope', '$uibModalInstance', 'modalParams', '$timeout', function($scope, $uibModalInstance, modalParams, $timeout) {
	
	$scope.init = function () {
		$scope.modalTitle = modalParams.modalTitle;
		$scope.modalDescription = modalParams.modalDescription;
		$scope.doComplete = modalParams.doComplete;
	};

	$scope.confirm = function() {
		if($scope.doComplete){
			$uibModalInstance.close('complete');
		}
		else {
			$uibModalInstance.close('dashboard');
		}
	};
	$scope.cancel = function() {
		$uibModalInstance.dismiss();
	};
	
	$scope.init();	
	
}]);

})();