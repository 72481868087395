(function () {
  'use strict';

  angular.module('users', [])
    /**
     * 
     * Users Service 
     */
    .service('UsersService', ['$rootScope', '$state', '$http', 'restApiUrl',

      function ($rootScope, $state, $http, restApiUrl) {


        var service = {};
        service.GetCollaborators = GetCollaborators;
        service.GetIndirectCollaborators = GetIndirectCollaborators;
        service.GetAll = GetAll;
        service.GetMe = GetMe;
        service.GetEmployees = GetEmployees;
        service.GetLeaders = GetLeaders;
        service.GetLeaderCollabs = GetLeaderCollabs;
        service.CreateEmployee = CreateEmployee;
        service.DeleteCollaborator = DeleteCollaborator;
        service.ReinstateCollaborator = ReinstateCollaborator;
        service.NewAssignment = NewAssignment;
        service.EditEmployee = EditEmployee;
        service.ChangeRole = ChangeRole;
        service.GetAllCollaborators = GetAllCollaborators;
        service.GetDashboard = GetDashboard;
        service.GetLogRecipients = GetLogRecipients;

        function GetCollaborators() {
          return $http.get(`${restApiUrl}/users/collaborators`);
        }

        function GetAllCollaborators() {
          return $http.get(`${restApiUrl}/users/collaborators/all`);
        }

        function GetIndirectCollaborators() {
          return $http.get(`${restApiUrl}/users/indirect-collaborators`);
        }

        function GetAll() {
          return $http.get(`${restApiUrl}/users`);
        }

        function GetMe() {
          return $http.get(`${restApiUrl}/users/me`);
        }

        function GetEmployees(params) {
          let uri = `${restApiUrl}/users`;
          return $http.get(uri, { params: params });
        }

        function GetLeaders(params) {
          let uri = `${restApiUrl}/users/leaders`;

          return $http.get(uri, { params: params });
        }

        function GetLeaderCollabs(leader_id) {
          return $http.get(`${restApiUrl}/users/${leader_id}/collaborators`)
        }

        function CreateEmployee(body) {
          console.log('POST /users', body);
          return $http.post(`${restApiUrl}/users`, body);
        }

        function DeleteCollaborator(employeeId) {
          console.log('DELETE /users/' + employeeId);
          return $http.delete(`${restApiUrl}/users/${employeeId}`);
        }

        function ReinstateCollaborator(employeeId) {
          console.log('POST /users/' + employeeId);
          return $http.post(`${restApiUrl}/users/${employeeId}`);
        }

        function NewAssignment(body) {
          console.log('POST /users/assign', body);
          return $http.post(`${restApiUrl}/users/assign`, body);
        }

        function EditEmployee(body) {
          return $http.put(`${restApiUrl}/users`, body);
        }

        function ChangeRole(role, employeeId) {
          if (role === 'ROLE_COLLABORATOR') {
            return $http.post(`${restApiUrl}/users/role/lead/${employeeId}`);
          } else {
            return $http.post(`${restApiUrl}/users/role/collaborator/${employeeId}`);
          }
        }

        function GetDashboard() {
          return $http.get(`${restApiUrl}/users/dashboard`)
        }

        function GetLogRecipients() {
          return $http.get(`${restApiUrl}/users/log-recipients`)
        }

        return service;

      }]);

})();