(function () {
  'use strict';

  angular.module('employees')

    .controller('EmployeeEditController', ['$scope', '$translate', '$uibModalInstance', 'modalParams', '$timeout', 'UsersService', 'EventsService', function ($scope, $translate, $uibModalInstance, modalParams, $timeout, UsersService, EventsService) {

      $scope.init = function () {
        $scope.employee = modalParams.employee;
        $scope.employee = angular.copy(modalParams.employee);
        $scope.callbackInit = modalParams.callbackInit;
        $scope.changeRole = false;
        $scope.role = $scope.employee.role
        // if($scope.employee.role === 'LEAD'){
        //   $scope.employee.role = 'Lider';
        // } else {
        //   $scope.employee.role = 'Colaborador';
        // }
      };

      // $scope.confirm = function() {
      //   $uibModalInstance.cancel();

      // };
      $scope.close = function () {
        $uibModalInstance.dismiss();
      };

      $scope.editEmployee = function () {
        $uibModalInstance.close('edit');
      }

      $scope.check = function () {
        return Object.keys($scope.employee).reduce((accum, current) => { return (accum && $scope.employee[current] != undefined && $scope.employee[current] != '') }, true);
      }

      $scope.mustChangeRole = function () {
        $scope.changeRole = !($scope.changeRole);
        if ($scope.role === 'ROLE_LEAD') {
          $scope.role = 'ROLE_COLLABORATOR';
        }
        else {
          $scope.role = 'ROLE_LEAD';
        }
      };

      $scope.editEmployee = function (employee) {
        $uibModalInstance.dismiss();
        console.log('going to edit employee', $scope.changeRole);
        employee.startDate = moment(employee.startDate, 'YYYY-MM-DD').format();
        employee.birthday = moment(employee.birthday, 'YYYY-MM-DD').format();
        swal({
          title: 'Editar colaborador',
          text: $scope.changeRole ? 'Atención: cambiará el rol del colaborador' : '',
          type: 'info',
          showCancelButton: true,
          confirmButtonClass: 'btn-info',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          closeOnConfirm: true,
          closeOnCancel: true,
        },
          function (isConfirm) {
            if (isConfirm) {
              setTimeout(function() {
                UsersService.EditEmployee(employee).then(function success(response) {
                  if ($scope.changeRole) {
                    UsersService.ChangeRole(employee.role, employee.employeeId).then(function (response) {
                      swal('Colaborador actualizado', 'Rol cambiado', 'success');
                      $scope.callbackInit();
                    }, function (error) {
                      $scope.callbackInit();
                      let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
                      swal('Error cambiando el rol del colaborador', errorText, 'error');
                    })
                  } else {
                    swal('Colaborador actualizado', '', 'success');
                    $scope.callbackInit();
                  }
                }, function error(error) {
                    if (error.data.validationErrors && error.data.validationErrors.length > 0) {
                        var errorDetails = $translate.instant('load.ERRORS.' + error.data.validationErrors[0].message);
                        for (var i = 1; i < error.data.validationErrors.length-1; i++) {
                            errorDetails = errorDetails + '\n' + $translate.instant('load.ERRORS.' + error.data.validationErrors[i].message);
                        }
                        swal($translate.instant('load.ERRORS.' + error.data.cause), errorDetails, 'error');
                    } else {
                        let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
                        swal('No ha sido posible actualizar el colaborador', errorText, 'error');
                    }
                    $scope.callbackInit();
                });
              }, 400)
            }
          });

      }
      $scope.init();

    }]);

})();