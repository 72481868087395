(function () {
    'use strict';

angular.module('dashboard')

.controller('FinishEventLeaderCtrl', [ '$scope', '$uibModalInstance', 'EventsService', 'UsersService', '$localStorage', function($scope, $uibModalInstance, EventsService, UsersService, $localStorage) {
	
	$scope.userMe = null;
	$scope.score = undefined;
	$scope.selected_collaborator = {};
	$scope.collaborators = [];
	$scope.description = '';
	$scope.shared = false;
	$scope.date = moment();
	$scope.commentToLider = false;
	$scope.isIndirectCollab = false;
	$scope.selected_indirect_collaborator = {};
	$scope.indirect_collabs = [];
	$scope.isDataLoadFinished = false;

	$scope.clear = function () {
		$scope.score = '';
		$scope.selected_collaborator = {};
		$scope.description = '';
		$scope.selected_indirect_collaborator = {};
		$scope.isIndirectCollab = false;
	}

	$scope.init = function () {
		UsersService.GetLogRecipients().then(function success(response){
			$scope.collaborators = [];
			response.data.result.forEach(collab => {
				let recipientType = '';
				if(collab.isIndirectCollab) {
					recipientType = ' ( Colaborador indirecto ) ';
				} else if (collab.isDirectCollab) {
					recipientType = ' ( Colaborador directo ) ';
				} else if (collab.isMyleader) {
					recipientType = ' ( Mi Lider ) ';
				} else if (collab.email === $localStorage.loggedUser.email)  {
					recipientType = ' ( Yo mismo ) ';
				}
				let label = collab.fullname + recipientType;
				$scope.collaborators.push({ id: collab.id, label: label, employee: collab });
			});
            $scope.collaborators.sort(function(a, b) {
                if (a.label > b.label) {
                    return 1;
                    }
                    if (a.label < b.label) {
                    return -1;
                    }
                    // a must be equal to b
                    return 0;
                });			
			$scope.isDataLoadFinished = true;
		}, function error(response) {
			console.log('error fetching collaborators', response);
		});
		
	};

	$scope.confirm = function() {

		$scope.submitted = true;

		if($scope.eventForm.$invalid){
			throw new Error('Formulario invalido');	
		};

		let body = {
			'description': $scope.description,
			'score': $scope.score,
			'shared': $scope.shared,
			'eventTime': moment($scope.date).format()
		};
		
		if ($scope.selectedColaborator.label.id === 'lead') {
			EventsService.PostCollabEvent(body).then(function success(response){
				$scope.clear();
				$uibModalInstance.close();
				swal('La nota fue guardada correctamente en la bitácora.', '', 'success');
			}, function error(response) {
				swal('Algo salió mal. La nota no pudo ser guardada en  la bitácora.', '', 'error');
			});
		} else {
			body.evaluatee = $scope.selectedColaborator.label.employee;
			EventsService.PostLeaderEvent(body).then(function success(response){
				$scope.clear();
				$uibModalInstance.close();
				swal('La nota fue guardada correctamente en la bitácora.', '', 'success');
			}, function error(response) {
				swal('Algo salió mal. La nota no pudo ser guardada en  la bitácora.', '', 'error');
			});
		};
	};


	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};
	
	$scope.selectScore = function (score) {
		if($scope.score === score){
			$scope.score = '';
		}
		else {
			$scope.score = score;
		}
	}


	$scope.init();	
	
}]);

})();