'use strict';

angular.module('dashboard', ['ui.bootstrap'])

	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('dashboard', {
			url: '/dashboard',
			templateUrl: 'components/dashboard/dashboard.html',
			controller: 'DashboardController'
		})
		.state('finish-event-leader', {
			url: '/finish-event-leader',
			templateUrl: 'components/dashboard/finish-event-leader.html',
			controller: 'FinishEventLeaderCtrl',
			resolve: {}
		})
		.state('finish-event-collab', {
			url: '/finish-event-collab',
			templateUrl: 'components/dashboard/finish-event-collab.html',
			controller: 'FinishEventCollabCtrl',
			resolve: {}
		})
		.state('edit-event', {
			url: '/edit-event',
			templateUrl: 'components/dashboard/edit-event.html',
			controller: 'EditEventController',
			resolve: {}
		});
	}])

	.controller('DashboardController', ['$scope', '$rootScope', '$state', '$uibModal', '$localStorage', 'EventsService', 'PerformanceEvaluationService', 'UsersService', function ($scope, $rootScope, $state, $uibModal, $localStorage, EventsService, PerformanceEvaluationService, UsersService) {

		$scope.description = '';
		$scope.loggedUsername = $scope.loggedUser.email;
		$scope.isAdmin = $rootScope.loggedUser.isAdmin;

		$scope.dashboard = {}

		$scope.events = {};
		$scope.events.myEvents = [];  // notas tomadas por mi
		$scope.events.mentionedEvents = [];  // notas dirigidas a mi
		$scope.events.thirdPartiesMentionsAboutMyTeamEvents = [];  //notas sobre alguien de mi equipo
		$scope.dataChartMood = [];

		$scope.evaluationStatistics = {'totalEvaluations': 0,
									   'finishedEvaluations': 0,
									   'pendingEvaluations': 0,
									   'generalPerformanceAverage':0.0,
									   'moodAverage':0.0};

		$scope.init = function () {	
			
			if($rootScope.loggedUser.isLeader){ 
				$scope.getStatistics();
			}

			$scope.getDashboard();
			$scope.getEvents();
			$scope.getEvaluations();
			$scope.getLogged();
			$scope.collapseNotes = false;
			$scope.collapseMentions = false;
			$scope.collapseThirdPartiesMentionsAboutMyTeam = false;
		}

		$scope.clear = function () {
			$scope.description = '';
		}

		$scope.collapseClickNotes = function () {
			$scope.collapseNotes = !($scope.collapseNotes);
		}

		$scope.isNoteCollapsed = function () {
			return $scope.collapseNotes;
		}

		$scope.collapseClickMentions = function () {
			$scope.collapseClickMentions = !($scope.collapseMentions);
		}

		$scope.isMentionsCollapsed = function () {
			return $scope.collapseMentions;
		}

		$scope.isThirdPartiesMentionsAboutMyTeamCollapsed = function () {
			return $scope.collapseThirdPartiesMentionsAboutMyTeam;
		}

		$scope.makeEvent = function (whoMakeEvent) {
			var modal = whoMakeEvent === 'LEADER' ? $state.get('finish-event-leader') : $state.get('finish-event-collab');

			modal.resolve.modalParams = function () {
				return {
					whoMakeEvent: whoMakeEvent,
					calledFrom: 'DASHBOARD'
				};
			};

			modal.windowClass = 'modal-zindex';
			modal.size = 'lg';
			var modalInstance = $uibModal.open(modal);

			modalInstance.result.then(function () {
				$scope.clear();
				$scope.getEvents();
			}, function () {
				//when error
			});
		}

		$scope.goMyEvaluation = function () {
			$state.go('my-evaluation');
		}

		$scope.goEvaluations = function () {
			$state.go('performance-evaluation');
		}

		$scope.goAssignments = function () {
			$state.go('assignments');
		}

		$scope.goReports = function () {
			$state.go('reports')
		}

		// ROLE_COLLABORATOR
		$scope.agreed_event = function (event_id) {
			EventsService.SetAgreed(event_id).then(function success(response) {
				console.log('success SetAgreed');
				$scope.getEvents();
			}, function error(response) {
				console.log('error SetAgreed', response);
			})
		}

		$scope.disagreed_event = function (event_id) {
			EventsService.SetDisagreed(event_id).then(function success(response) {
				console.log('success SetDisagreed');
				$scope.getEvents();
			}, function error(response) {
				console.log('error SetDisagreed', response);
			})
		}

		$scope.eventTexts = function (event) {
			if (event.shared) {
				event.sharedText = 'Evento compartido'
			}
			else { event.sharedText = 'Evento no compartido' }

			if (event.agreed == null) {
				event.agreedText = 'Aún no calificado'
			}
			else if (event.agreed) {
				event.agreedText = 'De acuerdo'
			}
			else { event.agreedText = 'En desacuerdo' }
		}

		$scope.eventDate = function (event) {
			let d = new Date(event.eventTime);
			event.eventTimeText = d.toLocaleDateString('es-AR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'});
		}


		$scope.getStatistics = function () {
			PerformanceEvaluationService.GetEvaluationStatistics().then(function (success) {
				$scope.dataChartMood = [
					{'category' : 'Enojado', 'value' : success.data.result[0].moodStatistics.madMoodNumber},
					{'category' : 'Decepcionado', 'value' : success.data.result[0].moodStatistics.sadMoodNumber},
					{'category' : 'Contento', 'value' : success.data.result[0].moodStatistics.neuterMoodNumber},
					{'category' : 'Feliz', 'value' : success.data.result[0].moodStatistics.happyMoodNumber}
				]
				$scope.dataChartGP = [
					{'category' : 'No alcanza', 'value' : success.data.result[0].gpStatistics.oneStarNumber},
					{'category' : 'Por debajo', 'value' : success.data.result[0].gpStatistics.twoStarNumber},
					{'category' : 'Alcanza', 'value' : success.data.result[0].gpStatistics.threeStarNumber},
					{'category' : 'Supera', 'value' : success.data.result[0].gpStatistics.fourStarNumber}
				]
				$scope.evaluationStatistics.totalEvaluations = success.data.result[0].totalEvaluationsCount;
				$scope.evaluationStatistics.finishedEvaluations = success.data.result[0].totalFinishedEvaluationCount;
				$scope.evaluationStatistics.pendingEvaluations = success.data.result[0].totalPendingEvaluations;

				$scope.evaluationStatistics.generalPerformanceAverage = success.data.result[0].gpStatistics.averageGeneralPerformance;
				$scope.evaluationStatistics.moodAverage = success.data.result[0].moodStatistics.averageMood;

	 			$scope.categoryTitle='"Ánimo"'
	  			$scope.valueTitle='"Total"'
				$scope.coloredStars   = [];
				$scope.uncoloredStars = [];
				$scope.halfStarOn = false;
				$scope.getStars($scope.evaluationStatistics.generalPerformanceAverage);
				$scope.percentageFinished = (($scope.evaluationStatistics.finishedEvaluations*100) / $scope.evaluationStatistics.totalEvaluations).toFixed(0);;
			}, function (error) {
				console.log('error fetching STATISTICS', error);})
		}

		$scope.getEvaluations = function () {
			PerformanceEvaluationService.GetPerformanceEvaluationMe().then(function (success) {
				if (success.data.result.length > 0 && success.data.result[0].state !== 'FINISHED' && success.data.result[0].state !== 'COMPLETED') {
					$scope.myEvaluation = success.data.result[0];
				}
			}, function (error) {
				console.log('error fetching myEvaluation', error);
			}),
				PerformanceEvaluationService.GetPendingPerformanceEvaluations().then(function (success) {
					$scope.evaluations = success.data.result;
				}, function (error) {
					console.log('error fetching evaluations');
				})
		}

		$scope.getEvents = function () {
			EventsService.GetAllEvents({}).then(function success(response) {
				$scope.events = response.data;
				$scope.events.myEvents = response.data.myEvents;
				$scope.events.mentionedEvents = response.data.mentionedEvents;
				$scope.events.myEvents.map((e) => { $scope.eventTexts(e); $scope.eventDate(e); return e });
				$scope.events.mentionedEvents.map((e) => { $scope.eventTexts(e); $scope.eventDate(e); return e });
				$scope.events.thirdPartiesMentionsAboutMyTeamEvents = response.data.thirdPartiesMentionsAboutMyTeamEvents;
				$scope.events.thirdPartiesMentionsAboutMyTeamEvents.map((e) => { $scope.eventTexts(e); $scope.eventDate(e); return e });
			}, function error(response) {
				console.log('error fetching events', response);
			});

		}

		$scope.getLogged = function () {
			UsersService.GetMe().then(function success(response) {
				$scope.me = response.data;
			}, function error(response) {
				swal('Colaborador no encontrado', `El email ${$localStorage.loggedUser.email} no se encuentra registrado. Por favor póngase en contacto con el administrador.`, 'warning');
			})
		}

		$scope.getDashboard = function () {
			UsersService.GetDashboard().then(function success(response) {
				$scope.dashboard = response.data;
			}, function error(error) {
			})
		}

		$scope.editEvent = function (event) {
			var modal = $state.get('edit-event');


			modal.resolve.modalParams = function () {
				return {
					event: event
				};
			};

			modal.windowClass = 'modal-zindex';
			modal.size = 'lg';
			var modalInstance = $uibModal.open(modal);

			modalInstance.result.then(function () {
				$scope.getEvents();
			}, function () {
				//when error
			});
		}

		$scope.getStars = function (n) {
			for (var i=1;i<=4;i++) {
				(i<=n) ? $scope.coloredStars.push(i) : $scope.uncoloredStars.push(i);
			}
			if ($scope.halfStarOn) {
				$scope.uncoloredStars.pop();
			}
		}

		$scope.roundCents = function (n) {
			if ((n - Math.floor($scope.generalPerformanceAverage)) < 0.75 && (n - Math.floor($scope.generalPerformanceAverage)) > 0.25){
				$scope.halfStarOn = true;
			}
			if ((n - Math.floor($scope.generalPerformanceAverage)) < 0.5){
				return Math.floor($scope.generalPerformanceAverage);
			}
			else
			{
				return Math.floor($scope.generalPerformanceAverage) + 1;
			}
		}

		$scope.deleteEvent = function (event_id) {
			swal({
				title: 'Eliminar nota',
				text: '¿Está seguro que desea eliminar la nota?',
				type: 'info',
				showCancelButton: true,
				confirmButtonClass: 'btn-info',
				confirmButtonText: 'Confirmar',
				cancelButtonText: 'Cancelar',
				closeOnConfirm: false,
				closeOnCancel: true,
			},
				function (isConfirm) {
					if (isConfirm) {
						EventsService.DeleteEvent(event_id).then(function success(response) {
							$scope.getEvents();
							swal('Nota eliminada', '', 'success');
						}, function error(response) {
							swal('No ha sido posible eliminar la nota.', response.data.cause, 'error');
						});
					}
				});
		}

		$scope.init();


	}]);
