(function () {
  'use strict';

angular.module('employees')

.controller('LeaderInfoController', [ '$scope', '$uibModalInstance', 'modalParams', '$timeout', 'UsersService', 'EventsService', function($scope, $uibModalInstance, modalParams, $timeout, UsersService, EventsService) {

$scope.init = function () {
  $scope.leader = modalParams.leader;
  UsersService.GetLeaderCollabs($scope.leader.id).then(function(success){
    $scope.collabs = success.data.result;
  }, function(error) {
    console.log('error fetching collaborators', error);
    swal('No es posible mostrar los colaboradores.', error.data.cause, 'error');
  })
};

$scope.confirm = function() {
  $uibModalInstance.cancel();

};
$scope.close = function() {
  $uibModalInstance.dismiss();
};


$scope.init();	

}]);

})();