angular.module('searchEntity', [])

.directive('searchEntity', function() {
	return {
		restrict : 'E',
		require: 'ngModel',
		templateUrl : 'app/gdp/shared/util/search-entity.directive.html',
		scope : {
			ngDisabled : '=',
			ngRequired : '=',
			showInfo : '=',
			width : '@',
			filterKey : '=',
			fontSize : '@',
			inputSearchPlaceholder : '@',
			entitySelected : '=entitySelectedObject',
			entityId : '=entitySelectedId'
		},
		controller :  [
            '$scope',
            '$http',
            '$element',
            '$attrs',
            'toaster',
            '$filter',
            'restApiUrl',
            function($scope, $http, $element, $attrs, toaster, $filter, restApiUrl) {
            	
            	$scope.items = null;
            	
            	$scope.triggerListAll = function() {
            		//Execute search with filter if value has selected
        			if($scope.entityId !== undefined){
        				$scope.$emit('listAll');
        				return;
        			}
					
				};
            	
            	
            	$scope.$on('loadEntity', function() {
            		
            		if($scope.entityId !== undefined){
                		
                		$http.get(restApiUrl + $attrs.service + '/findbyid/?id=' +  $scope.entityId, {}).then(function(response) {
    	        			
    	        			if (response.data.result.length > 0) {
    	        				$scope.select(response.data.result[0]);
    						} else {
    							$scope.hasResult = false;
    						}
    	
    	       		    },function(data) {
    	       		    	toaster.error('Error al intentar listar entidad');
    	       		    });
                		
                	}
					
				});
            	
            	
            	$scope.searchEntity = function(query) {
            	
	        		if(query.length === 0) {
	        			$scope.clear();
	        			$scope.hasResult = false;
	        			return;
	        		}
	        		if (query.length < 3) {
	        			$scope.entityId = undefined;
	        			$scope.hasResult = false;
	        			return;
	        		}
        		
	        		$http.get(restApiUrl + $attrs.service + '/findbyquery/?query=' +  query, {}).then(function(response) {
	        			
	        			if (response.data.result.length > 0) {
							$scope.hasResult = true;
							$scope.items = response.data.result
									.map(function(item) {
										return item;
									});
						} else {
							$scope.hasResult = false;
						}
	
	       		    },function(data) {
	       		    	toaster.error('Error al intentar listar entidad');
	       		    });
        		
            	};
            	
        		$scope.select = function(entity) {
        			
        			$scope.filterKey = entity.label;
        			$scope.entityId = entity.id;
        			$scope.items = [];
        			$scope.hasResult = false;
        			
        	    };
        	   
        	   $scope.clear = function() {
        			if ($scope.entitySelected !== undefined) {
        				$scope.entitySelected = undefined;
        			}
        			$scope.entityId = undefined;
        			$scope.entityType = undefined;
        			$scope.entityKey = '';
        			$scope.filterKey = '';
        		};
        		
           }]
	};
})

	
.directive('entityValidator', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel.$validators.entityId = function(modelValue) {
            	return modelValue !== undefined && modelValue !== '';
            };
        }
    };
});
