'use strict';

angular.module('app.vendor', [	'ui.router',
								'ngResource',
								'ngAnimate',
                                'ngSanitize',
                                'mgcrea.ngStrap',
                                'ngMessages',
                                'angular.filter',
								'toaster',
								'blockUI',
								'ui.select',
								'ui.mask',
								'ui.grid',
								'ui.grid.resizeColumns', 
								'ui.grid.autoResize',
								'ui.bootstrap',
								'ngFileUpload',
                                'angular-jwt',
                                'ngStorage',
                                'ya.nouislider',
                                'rw.moneymask',
                                'bspTemplates',
                                'satellizer'
                            ])


.config(['$provide', function($provide){
	
	console.log('Config app.vendor.module ...');
	
	//UI Grid language config
    $provide.decorator('GridOptions',['$delegate', 'i18nService', function($delegate, i18nService){
        var gridOptions;
        gridOptions = angular.copy($delegate);
        gridOptions.initialize = function(options) {
            var initOptions;
            initOptions = $delegate.initialize(options);
            return initOptions;
        };
        
        //es is the language prefix you want
        i18nService.setCurrentLang('es');
        return gridOptions;
    }]);
    
    //UI-Mask Config
    $provide.decorator('uiMaskConfig', ['$delegate', function($delegate) {
        //Disabled clear on blur for all masked input
    	$delegate.clearOnBlur = false;
        return $delegate;
    }]);
}]);