(function () {
    'use strict';

angular.module('authentication', [])


/**
 *  
 * Authentication Service 
 * 
 * 
 */
.service('AuthenticationService', [ '$localStorage', '$log', '$http', 'restApiUrl', '$auth', '$location',

    function($localStorage, $log, $http, restApiUrl, $auth, $location) {


    var service = {};
   
    service.Login = Login;
    service.Logout = Logout;
    service.Authentication = Authentication;    
    service.GetLoggedUser = GetLoggedUser;

    /**
     * Login 
     * 
     * @param {*} username 
     * @param {*} password 
     */
    function Login(username, password, callback) {
        //Data model
        $http.post(restApiUrl + '/login', { username: username, password: password }).then(  function success(response) {
                $log.debug('logged in');
                Authentication().then(function success(response){
                    if(response.data.email == undefined) {
                        callback(false, false, 'El usuario no posee email configurado. Contacte al administrador.');

                    } else {
                        //Store loggedUser data
                        $localStorage.loggedUser = {
                            'username': response.data.username,
                            'email': response.data.email,
                            'authorities': response.data.authorities
                        };

                        //Store roles flag
                        $localStorage.loggedUser.isLeader = HasRole('HACIENDOBIND_LIDER'),
                        $localStorage.loggedUser.isCollaborator = HasRole('HACIENDOBIND_COLABORADOR'),
                        $localStorage.loggedUser.isAdmin =  HasRole('HACIENDOBIND_ADMIN')
                        
                        callback(true, true);
                    }
                }, function error(response) {
                    console.log('error authentication', response);
                });
            },function error(response) {		
                callback(false, response.data);
        });  
    };
    
    /**
     * Logout and invalidate session
     * 
     */
    function Logout(callback) {
        console.debug('Login out...')
        $auth.logout();
        $localStorage.loggedUser = undefined;
        $location.path('/login');
    };

    function GetLoggedUser() {
        return $localStorage.loggedUser;
   }

    function Authentication() {
        return $http.get(`${restApiUrl}/authentication`);
    }
    return service;

}]);

})();