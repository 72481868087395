'use strict';

if(moment){
      moment.locale('es_AR', {
          relativeTime : {
              future: 'en %s',
              past:   'hace %s',
              s:  'segundos',
              m:  'un minuto',
              mm: '%d minutos',
              h:  'una hora',
              hh: '%d horas',
              d:  'un día',
              dd: '%d días',
              M:  'un mes',
              MM: '%d meses',
              y:  'un año',
              yy: '%d años'
          }
      });
      
      moment.locale('es_AR', {
          calendar : {
              lastDay : '[Ayer ] HH:mm',
              sameDay : '[Hoy ] HH:mm',
              nextDay : '[Mañana ] HH:mm',
              lastWeek : 'DD/MM/YYYY HH:mm',
              nextWeek : 'DD/MM/YYYY HH:mm',
              sameElse : 'DD/MM/YYYY HH:mm'
          }
      });
}