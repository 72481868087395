'use strict';

angular.module('configService', ['app.config', 'constants'])

    .factory('restApiUrl', ['RESTAPI_URL', 'RESTAPI_VERSION', function restApiUrlFactory(RESTAPI_URL, RESTAPI_VERSION) {

        var service = {};
        service.GetGHDModuleURL = GetGHDModuleURL;

        function GetGHDModuleURL() {
            return RESTAPI_URL + RESTAPI_VERSION;
        }

        return service.GetGHDModuleURL();
    }])


    .factory('responseObserver', ['$q', '$window', function ($q, $window) {
        return {
            'responseError': function (errorResponse) {
                console.log(errorResponse);
                switch (errorResponse.status) {
                    case 401:
                        if (errorResponse.data.error == null) {
                            $window.location.href = '/#!/login';
                        }
                        break;
                    case 403:
                        $window.location.href = '/#!/login';
                        break;
                    case -1:
                        $window.location.href = '/#!/login';
                        break;
                    }
                return $q.reject(errorResponse);
            }
        };
    }])


    .config(['$httpProvider', '$logProvider', 'blockUIConfig', 'LOG_LEVEL', function ($httpProvider, $logProvider, blockUIConfig, LOG_LEVEL) {

        //Catch error responses ( 401,403 )
        $httpProvider.interceptors.push('responseObserver');

        //Enabled log debug ( use always $log.debug for develop )
        if (LOG_LEVEL === 'DEBUG') {
            $logProvider.debugEnabled(true);
        } else {
            $logProvider.debugEnabled(false);
        }

        //Change the default template
        blockUIConfig.templateUrl = 'shared/util/block-ui-template.html';

        // Change the default overlay message
        blockUIConfig.message = 'Test ...';

        // Change the default delay before the blocking is visible
        blockUIConfig.delay = 250;

    }])
    

    .config(['$authProvider', 'OAUTH_PROVIDER_CLIENT_ID', 'OAUTH_PROVIDER_CALLBACK', 'OAUTH_PROVIDER_SCOPES' , function ($authProvider, OAUTH_PROVIDER_CLIENT_ID, OAUTH_PROVIDER_CALLBACK, OAUTH_PROVIDER_SCOPES) {
        $authProvider.google({
            clientId: OAUTH_PROVIDER_CLIENT_ID,
            url: OAUTH_PROVIDER_CALLBACK,
            scope: OAUTH_PROVIDER_SCOPES
        });
    }])

    
    .run(['$rootScope', '$location', '$localStorage', '$auth', function run($rootScope, $location, $localStorage, $auth) {

        // redirect to login page if not logged in and trying to access a restricted page
        $rootScope.$on('$locationChangeStart', function (event, next, current) {
            var publicPages = ['/login'];
            var restrictedPage = publicPages.indexOf($location.path()) === -1;
            
            //Check user sesion data and redirect.
            if ( !$auth.isAuthenticated()  && $location.path() === '/login' ) {
                $rootScope.loggedUser = undefined;
            } else if ($auth.isAuthenticated() && $location.path() === '/login') {
                $rootScope.loggedUser = $localStorage.loggedUser;
                $location.path('/dashboard');

            } else if (!$auth.isAuthenticated() && restrictedPage) {
                $rootScope.loggedUser = $localStorage.loggedUser;
                $location.path('/login');

            } else {
                $rootScope.loggedUser = $localStorage.loggedUser;
            }
        });

    }]);
