'use strict';

angular.module('my-collaborators', [])

.config([ '$stateProvider', function($stateProvider) {
    $stateProvider.state('my-collaborators', {
        url: '/my-collaborators',
        templateUrl: 'components/employees/my-collaborators.html',
        controller: 'MyCollaboratorsController'
    })

}])

.controller('MyCollaboratorsController', ['$scope', 'UsersService', 'restApiUrl', '$rootScope', '$state', '$http', '$uibModal', '$translate', function($scope, UsersService, restApiUrl, $rootScope, $state, $http, $uibModal, $translate) {

  $scope.init = function () {
    UsersService.GetCollaborators().then(function(success){
      $scope.collaborators = success.data.result;
    }, function(error){
      console.log('error fetching collaborators', error);
      let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
      swal('No es posible traer los colaboradores', errorText, 'error');
    })
  }

  $scope.moreInfo = function (employee) {
    var modal = $state.get('employee-info');
      modal.resolve.modalParams = function() {
        return {
          employee: employee,
          disableEdit: true
        };
      };
      
      modal.windowClass = 'modal-zindex';
      modal.size = 'lg';
      var modalInstance = $uibModal.open(modal);
      
      modalInstance.result.then(function() {

      }, function () {
        //when error
      });
  }

  $scope.init();

}]);