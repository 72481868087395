angular.module('util', [])

.directive('format', ['$filter', function ($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {

            /**
             * Format numbers with native "number" or "currency" filters.
             * 
             * @param {*} filterType 
             * @param {*} value 
             * @param {*} decimals 
             * @param {*} currencySymbol 
             */
            function doFormatFilter(filterType, value, decimals, currencySymbol){
                var formatedData = undefined;
                if(filterType === 'currency'){
                    //Check if currency symbol exist, else default is '$'
                    var currencySymbol = currencySymbol === undefined ? '$': currencySymbol;
                    
                    
                    formatedData = $filter(filterType)(parser(value), currencySymbol);
                    return formatedData;
                }else{
                    formatedData = $filter(filterType)(value, Number(decimals));
                    return formatedData;
                }
            }

            function isValidCurrencyFormat(value) {
                 //Check if is number or string
                 if(!isNaN(value)){
                    value = value.toString();
                }

                var regExpression = new RegExp('^(\\$ ?|\\$?)[0-9][0-9.]*[0-9]\\,?[0-9]{0,2}$', 'i');
                return regExpression.test(value.replace(/\s/g,''));
            }

            function formatter(value) {
                if(value === undefined || !isValidCurrencyFormat(value)){
                    return '0';
                }

                return doFormatFilter(attrs.format, value, attrs.formatDecimals, attrs.currencySymbol);
            }

            function parser(value) {

                //Check if is number or string
                if(!isNaN(value)){
                    value = value.toString();
                }

                if(!isValidCurrencyFormat(value)){
                    return '0';
                }

                //If is number with dot in decimals ej: 1200.00
                if(value.match(/[^\d|\-+|\.+]/g) === null){
                    return value;
                }else{
                    //Remove dot and currency symbol
                    var plainNumber = value.replace(/[^\d|\-+|\,+]/g, '');
                    //Replace comma with dot for currency filter ( Workaround for angular currency filter).
                    var p = $filter('comma2decimal')(plainNumber);
                    return p;
                }
               
            }
    

             // This sets the format/parse to happen on blur/focus
             elem.on('blur', function (event) {
                ngModel.$setViewValue(formatter(this.value));
                ngModel.$render();
            });

            elem.on('change', function (event) {
                ngModel.$setViewValue(formatter(this.value));
                ngModel.$render();
            });

             // Model Formatter
             ngModel.$formatters.push(formatter);
             // Model Parser
             ngModel.$parsers.push(parser);
           

        }
    };
}])

.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind('keydown keypress', function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter);
                });
                event.preventDefault();
            }
        });
    };
  });