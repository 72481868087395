angular.module("app.config", [])

.constant("RESTAPI_VERSION", "/v1")

.constant("RESTAPI_URL", "http://artekium.empower-us.com")

.constant("OAUTH_PROVIDER_CLIENT_ID", "984200124091-i75tcrep4h8r7q2k7480ub07fe5pb1b3.apps.googleusercontent.com")

.constant("OAUTH_PROVIDER_CALLBACK", "http://artekium.empower-us.com/v1/callback")

.constant("OAUTH_PROVIDER_SCOPES", [
	"profile",
	"email"
])

.constant("LOG_LEVEL", "DEBUG")

;