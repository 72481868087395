'use strict';

angular.module('filters', [])

.filter('capitalize', function() {
    return function(input) {
      return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    }
})

.filter('comma2decimal', [
  function() { // should be altered to suit your needs
      return function(input) {
      var ret=(input)?input.toString().trim().replace(',','.'):null;
          return parseFloat(ret);
      };
}])

.filter('decimal2comma', [
	function() {
		return function(input) {
			var ret=(input)?input.toString().replace('.',','):null;
			if(ret){
				var decArr=ret.split(',');
				if(decArr.length>1){
					var dec=decArr[1].length;
					if(dec===1){ret+='0';}
				}
			}
			return ret;
		};
	}
])

.filter('currencyDescription', function() {
  return function(currency) {

      if (currency === '$') {
          return 'ARS ( Pesos Argentinos )';
      } else if(currency === 'US$') {
          return 'USD ( Dolares )';;
      }else {
        return 'ARS ( Pesos Argentinos )';
      }
  };
})

.filter('percentage', function() {
	  return function(value) {
		return (value * 100).toFixed(2) + '%';
	};
})



.filter('toDate', function() {
    return function(dateExpression, format) {
    	if(Array.isArray(dateExpression)) {
    		return new Date(dateExpression.slice(0,3));
    	} else if(format) {
			return moment(dateExpression, format).toDate()
		}
    	return new Date(dateExpression);
    };
})



;