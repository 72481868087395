'use strict';

angular.module('log', ['angular-echarts'])

	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider.state('log', {
			url: '/log',
			templateUrl: 'components/log/log.html',
			controller: 'LogController'
		})
	}])

	.controller('LogController', ['$scope', 'EventsService', 'UsersService', '$uibModal', '$state', '$localStorage', function ($scope, EventsService, UsersService, $uibModal, $state, $localStorage) {

		$scope.selected_collaborator = null;
		$scope.addLider = true;
		$scope.includeType = 'reverseFalse';
		$scope.currentReverse = null;
		$scope.events = [];
		$scope.isDataLoadFinished = false;

		$scope.init = function () {
			$scope.collapse = false;
			$scope.collapseFilter = false;
			$scope.initFilter();
			$scope.getCollabs();
		}

		$scope.initFilter = function () {
			var filter = {};
			filter.dateFrom = moment().subtract(30, 'days').toDate();
			filter.dateTo = moment().toDate();
			$scope.filter = filter;
		}

		$scope.eventDate = function (event) {
			let d = new Date(event.eventTime);
			event.eventTimeText = d.toLocaleDateString('es-AR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'});
		}

		$scope.getEvents = function () {
			let from = moment($scope.filter.dateFrom).valueOf();
			let to = moment($scope.filter.dateTo).valueOf();
			let params = {
				'from': from,
				'to': to,
				'reverse': $scope.includeType === 'reverseTrue' ? true : false,
				'thirdPartiesMentionsAboutMyTeam': $scope.includeType === 'thirdPartiesMentionsAboutMyTeam' ? true : false 
			};
			if ($scope.selected_collaborator && $scope.selected_collaborator !== null) {
				if ($scope.selected_collaborator.label.id === 'lead') {
					params['leader'] = true;
				} else {
					params['collaborator'] = $scope.selected_collaborator.label.employee.email;
				}
			}
			EventsService.GetEvents(params).then(function success(response) {
				$scope.events = response.data.result;
				$scope.currentReverse = $scope.includeType;
				$scope.events.map((e) => { $scope.eventTexts(e); $scope.eventDate(e); return e });
			}, function error(response) {
				console.log('error fetching events', response);
			});

		}

		$scope.getCollabs = function () {
			UsersService.GetLogRecipients().then(function success(response){
				$scope.collaborators = [];
				response.data.result.forEach(collab => {
					let recipientType = '';
					if(collab.isIndirectCollab) {
						recipientType = ' ( Colaborador indirecto ) ';
					} else if (collab.isDirectCollab) {
						recipientType = ' ( Colaborador directo ) ';
					} else if (collab.isMyleader) {
						recipientType = ' ( Mi Lider ) ';
					} else if (collab.email === $localStorage.loggedUser.email)  {
						recipientType = ' ( Yo mismo ) ';
					}
					let label = collab.fullname + recipientType;
					$scope.collaborators.push({ id: collab.id, label: label, employee: collab });
				});
				$scope.collaborators.sort(function(a, b) {
					if (a.label > b.label) {
						return 1;
						}
						if (a.label < b.label) {
						return -1;
						}
						// a must be equal to b
						return 0;
					});			
				$scope.isDataLoadFinished = true;
			}, function error(response) {
				console.log('error fetching collaborators', response);
			});
		}

		$scope.eventTexts = function (event) {
			if (event.shared) {
				event.sharedText = 'Evento compartido'
			} else { 
				event.sharedText = 'Evento no compartido' 
			}

			if (event.agreed == null) {
				event.agreedText = 'Aún no calificado'
			} else if (event.agreed) {
				event.agreedText = 'De acuerdo'
			} else { 
				event.agreedText = 'En desacuerdo' 
			}
		}

		$scope.editEvent = function (event) {
			var modal = $state.get('edit-event');
			modal.resolve.modalParams = function () {
				return {
					event: event
				};
			};
			modal.windowClass = 'modal-zindex';
			modal.size = 'lg';

			var modalInstance = $uibModal.open(modal);
			modalInstance.result.then(function () {
				$scope.getEvents();
			}, function () {
				//when error
			});
		}

		$scope.deleteEvent = function (event_id) {
			swal({
				title: 'Eliminar nota',
				text: '¿Está seguro que desea eliminar la nota?',
				type: 'info',
				showCancelButton: true,
				confirmButtonClass: 'btn-info',
				confirmButtonText: 'Confirmar',
				cancelButtonText: 'Cancelar',
				closeOnConfirm: false,
				closeOnCancel: true,
			},
			function (isConfirm) {
				if (isConfirm) {
					EventsService.DeleteEvent(event_id).then(function success(response) {
						$scope.getEvents();
						swal('Nota eliminada', '', 'success');
					}, function error(response) {
						swal('No ha sido posible eliminar la nota.', response.data.cause, 'error');
					});
				}
			});
		}


		$scope.collapseFilterClick = function (cFilter) {
			$scope.cFilter = !($scope.cFilter);
		}

		$scope.isFilterCollapsed = function (filter) {
			return $scope.filter;
		}

		$scope.collapseClick = function (name) {
			$scope.name = !($scope.name);
		}

		$scope.isCollapsed = function (name) {
			return $scope.name;
		}

		$scope.cleanFilter = function () {
			$scope.selected_collaborator = null;
			$scope.addLider = true;
			$scope.includeType = 'reverseFalse';
			$scope.currentReverse = null;
			$scope.events = [];
			$scope.initFilter();
		}

		
		$scope.init();
	}])

