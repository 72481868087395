angular.module('validateCbu', [])


.directive('validateCbu', function () {
	function validateLength(cbu) {
		if (cbu === '') {
    		return true;
    	}
		
		if (cbu.length !== 22) { 
			return false; 
		}
		return true;
	}
	
	function validateBankCode(code) {
		if (code.length !== 8) { 
			return false; 
		}
		var bank = code.substr(0,3);
		var branch = code.substr(3,4);
		var verificationDigit = parseInt(code[7]);

		var sum = bank[0] * 7 + bank[1] * 1 + bank[2] * 3 + branch[0] * 9 + branch[1] * 7 + branch[2] * 1 + branch[3] * 3;

		var diff = 10 - sum % 10;

		return verificationDigit !== 0 && diff === verificationDigit || verificationDigit === 0 && diff === 10;
	}
	
	function validateAccount(account) {
		if (account.length !== 14) { 
			return false; 
		}
		var verificationDigit = parseInt(account[13]);
		var sum = account[0] * 3 + account[1] * 9 + account[2] * 7 + account[3] * 1 + account[4] * 3 + account[5] * 9 + account[6] * 7 + account[7] * 1 + account[8] * 3 + account[9] * 9 + account[10] * 7 + account[11] * 1 + account[12] * 3;
		var diff = 10 - sum % 10;
		return verificationDigit !== 0 && diff === verificationDigit || verificationDigit === 0 && diff === 10;
	}

	function isValid(cbu) {
		return validateLength(cbu) && validateBankCode(cbu.substr(0,8)) && validateAccount(cbu.substr(8,14));
	}
    
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, controller) {
            //DOM -> model validation
            controller.$parsers.unshift(function(value) {
                var valid = false;
                if (angular.isDefined(value)) {
                    valid = isValid(value);
                }
                controller.$setValidity('cbuMustBeReal', valid);
                return value;
            });

            //model -> DOM validation
            controller.$formatters.unshift(function(value) {
                if (angular.isDefined(value) && isFinite(value) && value !== null) {
                    controller.$setValidity('cbuMustBeReal', isValid(value));
                    return value;
                }
                return value;
            });
        }
    };
});