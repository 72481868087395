(function () {
    'use strict';

angular.module('performance-evaluation')

.controller('MyEvaluationController', [ '$scope', 'PerformanceEvaluationService', 'PeriodService', '$state', '$rootScope', '$localStorage', '$window', function($scope, PerformanceEvaluationService, PeriodService, $state, $rootScope, $localStorage, $window) {
    $scope.loggedUsername = $scope.loggedUser.email;
    $scope.disabledInit = false;

    $scope.selected_period = null;
    $scope.selected_general_performance = null;
    $scope.generalPerformances = [
        'Supera las expectativas',
        'Alcanza las expectativas',
        'Por debajo de las expectativas',
        'No alcanza las expectativas',
    ]

	$scope.init = function () {
        $scope.evaluation = null,
        PerformanceEvaluationService.GetPerformanceEvaluationMe().then(function success(response) {
            if(response.data.result.length > 0 && response.data.result[0].state !== 'FINISHED'){ //  &&
                $scope.evaluation = response.data.result[0]
                // Disabled button if loggedUser es collaborator and the leader didnt complete the evaluation yet
                if($scope.loggedUsername === $scope.evaluation.evaluatedDto.email && ($scope.evaluation.state === 'PENDING'))  {
                    $scope.disabledInit = true;
                }
            }
        }, function error(_response){
            $scope.evaluation = null;
            console.log('error GetPerformanceEvaluationMe');
        });
        $scope.getPeriods();

    };
    
    $scope.do_evaluation = function (evaluation) {
        if ($scope.loggedUsername === evaluation.evaluatorDto.email) {
            $scope.currentStep = evaluation.currentStepEvaluator

        } else {
            if ($scope.loggedUsername === evaluation.evaluatedDto.email) {
                $scope.currentStep = evaluation.currentStepEvaluatee
            }
        }
        $localStorage.actualPerformanceId = evaluation.id;
        if(evaluation.type === 'LEAD_LEAD'){
            switch ($scope.currentStep) {
                case 1:
                    $state.go('ltl-first-step', {evaluationId: evaluation.id});
                    break;
                case 2:
                    $state.go('ltl-second-step', {evaluationId: evaluation.id});
                    break;
                case 3:
                    $state.go('ltl-third-step', {evaluationId: evaluation.id});
                    break;
                case 4:
                    $state.go('ltl-fourth-step', {evaluationId: evaluation.id});
                    break;
            }
        }
        else if(evaluation.type === 'LEAD_COLLABORATOR'){
            switch ($scope.currentStep) {
                case 1:
                    $state.go('ltc-first-step', {evaluationId: evaluation.id});
                    break;
                case 2:
                    $state.go('ltc-second-step', {evaluationId: evaluation.id});
                    break;
                case 3:
                    $state.go('ltc-third-step', {evaluationId: evaluation.id});
                    break;
            }
        }
    }

    $scope.reloadSidebar = function () {
        $rootScope.$broadcast('refresh');
    }

    $scope.getPeriods = function () {
        PeriodService.GetPeriods({}).then(function success(response) {
            $scope.periods = response.data.result;
        }, function error(response) {
            console.error('### error fetching periods ', response);
        });
    }

    $scope.getEvaluationsFinished = function () {
        let params = {};
        if($scope.selected_period && $scope.selected_period !== null) {
            params['period'] = JSON.parse($scope.selected_period).id;
        }
        if($scope.selected_general_performance && $scope.selected_general_performance !== null) {
            params['generalPerformance'] = $scope.selected_general_performance;
        }
        params['state'] = 'FINISHED',
        params['lookupAs'] = 'EVALUATEE',
        PerformanceEvaluationService.GetEvaluationsFinished(params).then(function (success){
            $scope.performanceEvaluations = success.data.result;
            $scope.collapseGrilla = true;
        }, function (error){
            console.log('error fetching evaluations', error);
            swal('No es posible mostrar las evaluaciones', error.data.cause, 'error');
        })
    }

    $scope.watchEvaluation = function (evaluation) {
        if(evaluation.evaluateeRole === 'ROLE_LEAD'){

            let param = {evaluationId: evaluation.id, disabled: true};
            $state.go('ltl-fourth-step', param);
        }
        else if (evaluation.evaluateeRole === 'ROLE_COLLABORATOR'){
            let param = {evaluationId: evaluation.id, disabled: true};
            $state.go('ltc-third-step', param);
        }
    }

    $scope.print = function (evaluation) {
        PerformanceEvaluationService.DocumentPrint(evaluation.id).then( function success(response) {
            var newBlob = new Blob([response.data], {type: 'application/pdf'});
            const data = URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download='HB-' + evaluation.evaluateeFullname.replace(' ', '_') + '-' + evaluation.period.replace(' ', '_') + '.pdf';
            link.click();
            setTimeout(function(){
                URL.revokeObjectURL(data);
            }, 1000);
            $window.open(trash, '_blank');
        }, function error(error) {
            swal('No es posible procesar la solicitud', error.data.cause, 'error');
        });
    };

	$scope.init();	
	
}]);

})();