'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlThirdStepController', ['$scope', '$stateParams', '$state', '$rootScope', '$uibModal', '$translate', 'PerformanceEvaluationService', '$localStorage', function ($scope, $stateParams, $state, $rootScope, $uibModal, $translate, PerformanceEvaluationService, $localStorage) {

		$scope.init = function () {
			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}

			$scope.star = 0;
			$scope.selectedGP = undefined;
			$scope.currentState = $state;

			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				$scope.useObjectives = $scope.evaluation.nextObjectivesDto;
				$scope.loggedUsername = $scope.loggedUser.email;
				$scope.setMood();
				$scope.setStar();
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator;
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee;
					}
				}

				$scope.isDisabled = true;
			}, function error(_error) {
				console.log('error fetching evaluation', $scope.evalId)
			})
		};

		$scope.isEvaluator = function () {
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				return true
			}
			return false;
		}

		$scope.goToConditional = function () {
			let isEvaluator = $scope.isEvaluator();
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else {
				$state.go('my-evaluation');
			}
		}

		$scope.save = function (evaluation) {
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				$scope.evaluation.currentStepEvaluator = 3;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
					$scope.evaluation.currentStepEvaluatee = 3;
				}
			}
			PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
				function success(_response) {
					swal('Guardada', 'Evaluación guardada con éxito', 'success');
				}, function error(error) {
					swal('Error al guardar la evaluación', 'Error desconocido', 'error')
					console.log('failure in continue evaluation-1', error);
				}
			);
		};

		$scope.continue_evaluation = function (evaluation) {

			let modalTitle = '';
			let modalDescription = '';
			let showRevision = undefined;

			$scope.submitted = true;

			// Check if step-4 (revision) must be shown
			if ($scope.evaluation.evaluatorDto.email === $scope.loggedUsername &&
				($scope.evaluation.state === 'IN_PROGRESS_COLLAB' || $scope.evaluation.state === 'IN_PROGRESS')) {
				modalTitle = 'Evaluación completa';
				modalDescription = `Espere a que el Colaborador ${$scope.evaluation.evaluatedDto.fullname} termine de completar la evaluación para finalizarla.`;
				showRevision = false;
			}
			else if ($scope.evaluation.evaluatorDto.email === $scope.loggedUsername && ($scope.evaluation.state === 'COMPLETED' || $scope.evaluation.state === 'IN_PROGRESS_LEAD')) {
				showRevision = true;
			}
			else if ($scope.evaluation.evaluatedDto.email === $scope.loggedUsername) {
				modalTitle = 'Evaluación completa';
				modalDescription = `Gracias por completar la evaluación. Ahora ${$scope.evaluation.evaluatorDto.fullname} debe finalizar la evaluación.`;
				showRevision = false;
			}

			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				$scope.evaluation.currentStepEvaluator = 3;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
					$scope.evaluation.currentStepEvaluatee = 3;
				}
			}

			if (showRevision) {
				// Save evaluation and go to step4 Revision
				PerformanceEvaluationService.PostPerformanceEvaluation(evaluation).then(
					function success(_response) {
						$state.go('ltl-fourth-step', { evaluationId: evaluation.id });
					}, function error(error) {
						if(error.data.cause === 'STEP_THREE_INCOMPLETE_FIELDS'){
							let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
							swal('Error al guardar la evaluación.', errorText, 'error');
							$scope.validationErrors = error.data.validationErrors;
						}else{
							swal('Error al guardar la evaluación', 'Error desconocido', 'error')
						}
						console.log('failure in continue evaluation-1', error);
					}
				);
			}

			else {
				PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
					function success(_response) {
						swal({
							title: modalTitle,
							text: modalDescription,
							type: 'info',
							showCancelButton: true,
							confirmButtonClass: 'btn-info',
							confirmButtonText: 'Confirmar',
							cancelButtonText: 'Cancelar',
							closeOnConfirm: false,
							closeOnCancel: true,
						}, function (isConfirm) {
							if (isConfirm) {
								swal('Evaluación guardada con éxito', '', 'success')
								$state.go('dashboard');
								}});
							}, function error(error) {
									if(error.data.cause === 'STEP_THREE_INCOMPLETE_FIELDS'){
										let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
										swal('Error al guardar la evaluación.', errorText, 'error');
										$scope.validationErrors = error.data.validationErrors;
									}else{
										swal('Error al guardar la evaluación', 'Error desconocido', 'error')
									}
								}
							);
					}
				}

		$scope.back = function (id, _evaluation) {
			$state.go('ltl-second-step', { evaluationId: id });
		}

		$scope.newOkr = function () {
			let emptyOkr = {
				'description': '',
				'complianceDegree': 0,
				'keyResults': []
			};
			$scope.useObjectives.push(emptyOkr);
		}

		$scope.newKeyResult = function (index) {
			let emptyKeyResult = {
				'description': '',
				'calification': 0
			}
			$scope.useObjectives[index].keyResults.push(emptyKeyResult);
		}

		$scope.deleteObjective = function (index) {
			$scope.useObjectives.splice(index, 1);
		}

		$scope.deleteKeyResult = function (index, keyResultIndex) {
			$scope.useObjectives[index].keyResults.splice(keyResultIndex, 1);
		}

		$scope.selectMood = function (mood) {
			if ($scope.evaluation.mood === mood) {
				$scope.evaluation.mood = undefined;
			}
			else {
				$scope.evaluation.mood = mood;
			}
		};


		$scope.selectGeneralPerformance = function () {
			switch ($scope.star) {
				case 1:
					$scope.evaluation.generalPerformance = 'No alcanza las expectativas';
					$scope.selectedGP = 1;
					break;
				case 2:
					$scope.evaluation.generalPerformance = 'Por debajo de las expectativas';
					$scope.selectedGP = 2;
					break;
				case 3:
					$scope.evaluation.generalPerformance = 'Alcanza las expectativas';
					$scope.selectedGP = 3;
					break;
				case 4:
					$scope.evaluation.generalPerformance = 'Supera las expectativas';
					$scope.selectedGP = 4;
					break;
			}
			$scope.oldSelectedGP = $scope.selectedGP;
		}

		$scope.setMood = function(){
			if($scope.evaluation.mood === ''){
				$scope.evaluation.mood = undefined;
			}
		}

		$scope.setStar = function () {
			if ($scope.evaluation.generalPerformance != '') {
				switch ($scope.evaluation.generalPerformance) {
					case 'No alcanza las expectativas':
						$scope.selectedGP = 1;
						break;
					case 'Por debajo de las expectativas':
						$scope.selectedGP = 2;
						break;
					case 'Alcanza las expectativas':
						$scope.selectedGP = 3;
						break;
					case 'Supera las expectativas':
						$scope.selectedGP = 4;
						break;
				}
			}
		}

		$scope.collaborator_can_see = function (evaluation) {
			return ($scope.loggedUserRoleInEval === 'EVALUATOR')
			|| (($scope.loggedUserRoleInEval === 'EVALUATEE')
			&& ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes($scope.evaluation.state));
		}

		$scope.mouseOver = function (starNumber) {
			if ($scope.loggedUserRoleInEval === 'EVALUATOR') {
				$scope.oldSelectedGP = $scope.selectedGP;
				$scope.selectedGP = starNumber;
				$scope.star = starNumber;
			}
		}

		$scope.mouseLeave = function (starNumber) {
			if ($scope.loggedUserRoleInEval === 'EVALUATOR') {
				$scope.selectedGP = $scope.oldSelectedGP;
				$scope.star = 0;
			}
		}
		$scope.init();

	}]);