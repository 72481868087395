'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlSecondStepController', ['$scope', '$stateParams', '$state', '$rootScope', '$translate', 'PerformanceEvaluationService', '$localStorage', function ($scope, $stateParams, $state, $rootScope, $translate, PerformanceEvaluationService, $localStorage) {

		$scope.init = function () {

			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}
			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				// $scope.evaluation.valuesCalification.sort((x, y) => $scope.compareValueName(x, y));
				$scope.vafs = $scope.removeRepeated(['valueName', 'aspectName'], $scope.evaluation.valuesCalification);
				$scope.loggedUsername = $rootScope.loggedUser.email;
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee
					}
				}
			}, function error(_error) {
				console.log('error fetching evaluation', $rootScope.evalId)
			})
		};

		$scope.compareValueName = function (a, b) {
			if (a.valueName == b.valueName) {
				return $scope.compareAspectName(a, b);
			}
			if (a.valueName < b.valueName) {
				return -1;
			}
			if (a.valueName > b.valueName) {
				return 1;
			}
		}

		$scope.compareAspectName = function (a, b) {
			if (a.aspectName < b.aspectName) {
				return -1;
			}
			if (a.aspectName > b.aspectName) {
				return 1;
			}
			return 0;
		}

		$scope.removeRepeated = function (repeatKeys, list) {
			let firstValue = '';
			const result = list.map(x => Object.assign({}, x));
			repeatKeys.forEach(key => {
				result.forEach((obj, index) => {
					obj['original'] = list[index];
					if (key == repeatKeys[0]) {
						obj['group'] = obj[repeatKeys[0]];
					}
					if (firstValue != obj[key]) {
						firstValue = obj[key];
					} else {
						obj[key] = '';
					}
				})
			});
			return result;
		}

		$scope.collaborator_can_see = function (evaluation) {
			return ($scope.loggedUserRoleInEval === 'EVALUATOR')
			|| (($scope.loggedUserRoleInEval === 'EVALUATEE') 
			&& ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes($scope.evaluation.state));
		}

		$scope.recoverValueAspects = function () {
			$scope.evaluation.valuesCalification = $scope.vafs.map(x => x.original);
		}

		$scope.save = function (evaluation) {
			$scope.recoverValueAspects();
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				$scope.evaluation.currentStepEvaluator = 2;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
					$scope.evaluation.currentStepEvaluatee = 2;
				}
			}
			PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
				function success(_response) {
					swal('Guardada', 'Evaluación guardada con éxito', 'success');
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					console.log('failure in continue evaluation-1');
				}
			);
		};

		$scope.continue_evaluation = function (evaluation) {
			$scope.recoverValueAspects();
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email) {
				$scope.evaluation.currentStepEvaluator = 2;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email) {
					$scope.evaluation.currentStepEvaluatee = 2;
				}
			}

			$scope.submitted = true;

			PerformanceEvaluationService.PostPerformanceEvaluation(evaluation).then(
				function success(_response) {
					$state.go('ltl-third-step', { evaluationId: evaluation.id });
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					$scope.validationErrors = error.data.validationErrors;
					console.log('failure in continue evaluation-4');
				}
			);
		};

		$scope.collaborator_can_see = function (evaluation) {
			return ($scope.loggedUserRoleInEval === 'EVALUATOR')
			|| (($scope.loggedUserRoleInEval === 'EVALUATEE') 
			&& ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes($scope.evaluation.state));
		}

		$scope.back = function (id, _evaluation) {
			// TODO ask to POST evaluation ?
			$state.go('ltl-first-step', { evaluationId: id });
		}

		$scope.init();

	}]);