'use strict';

angular.module('employees-relationships', ['paginationModule'])

.config([ '$stateProvider', function($stateProvider) {
    $stateProvider.state('assignments', {
        url: '/assignments',
        templateUrl: 'components/employees/employees-relationships.html',
        controller: 'EmployeesRelationshipsCtrl'
    })
    .state('leader-info', {
      templateUrl: 'components/employees/leader-info.html',
      controller: 'LeaderInfoController',
      resolve: {}
    })
    .state('new-relationship', {
      templateUrl: 'components/employees/new-relationship.html',
      controller: 'NewRelationshipController',
    })

}])

.controller('EmployeesRelationshipsCtrl', ['$scope', 'UsersService', 'restApiUrl', '$rootScope', '$timeout', '$state', '$http', '$uibModal', function($scope, UsersService, restApiUrl, $rootScope, $timeout, $state, $http, $uibModal) {

  $scope.collapsedFilter = false;

  $scope.init = function () {

    // Variables de los filtros.
    $scope.fullname = null;
    $scope.management = null;
    $scope.employeeId = null;
    $scope.subsidiary = null;

    $scope.getLeaders();
  }

  $scope.collapseFilterClick = function () {
    $scope.collapsedFilter = !($scope.collapsedFilter);
  }

  $scope.isFilterCollapsed = function () {
    return $scope.collapsedFilter;
  }

  $scope.makeFilter = function() {
    let filter = {};

    if($scope.fullname !== null && $scope.fullname !== '') {
      filter['fullname'] = $scope.fullname;
    }
    if($scope.management !== null && $scope.management !== '') {
      filter['management'] = $scope.management;
    }
    if($scope.employeeId !== null && $scope.employeeId !== '') {
      filter['employeeId'] = $scope.employeeId;
    }
    if($scope.subsidiary !== null && $scope.subsidiary !== '') {
      filter['subsidiary'] = $scope.subsidiary;
    }
    
    var pageable = {};
    pageable['page'] = 1;
    pageable['limit'] = 10;
    filter.pageable = pageable;

    return filter;
  }


  $scope.getLeaders = function (currentPage) {
    let filter = $scope.makeFilter();
    filter.pageable.page = currentPage;
    UsersService.GetLeaders(filter).then(function(success){
      $timeout(function () { $scope.$broadcast('loadPagination', success.data.paging); });
      $scope.leaders = success.data.result;
    }, function(error){
      console.log('error fetching leaders', error);
      swal('No es posible recuperar información de los líderes', error.data.cause, 'error');
    })
  }


  $scope.cleanFilter = function () {
    $scope.fullname = null;
    $scope.management = null;
    $scope.employeeId = null;
    $scope.subsidiary = null;
  }

  $scope.moreInfo = function (employee) {
    var modal = $state.get('employee-info');
      modal.resolve.modalParams = function() {
        return {
          employee: employee
        };
      };
      
      modal.windowClass = 'modal-zindex';
      modal.size = 'lg';
      var modalInstance = $uibModal.open(modal);
      
      modalInstance.result.then(function(val) {
        if (val === 'edit') {
          $scope.editEmployee(employee);
        }

      }, function () {
        //when error
      });
  }

  $scope.seeCollabs = function (leader) {
    var modal = $state.get('leader-info');
      modal.resolve.modalParams = function(){
        return {
          leader: leader
        };
      };
      modal.windowClass = 'modal-zindex';
      modal.size = 'lg';
      var modalInstance = $uibModal.open(modal);
      
      modalInstance.result.then(function() {

      }, function () {
        //when error
      });
  }

  $scope.editEmployee = function (employee) {
    var modal = $state.get('employee-edit');
    modal.resolve.modalParams = function () {
      return {
        employee: employee,
        callbackInit: $scope.init
      };
    };

    modal.windowClass = 'modal-zindex';
    modal.size = 'lg';
    var modalInstance = $uibModal.open(modal);

    modalInstance.result.then(function (val) {
      $scope.init();
    }, function () {
      //when error
    });
  }

  $scope.newRelationship = function () {
    $state.go('new-relationship');
  }
  $scope.init();

}]);