angular.module('validateCuit', [])


.directive('validateCuit', function () {
    function isValid(plainCuit) {
    	if (plainCuit === '') {
    		return true;
    	}
    	
        if (plainCuit.length === 11) {
            var sum = 0;
            var mult = [ 5, 4, 3, 2, 7, 6, 5, 4, 3, 2 ];

            for (i = 0; i < mult.length; i++) {
                sum += parseInt(plainCuit.charAt(i)) * mult[i];
            }

            var mod = sum % 11;
            var digit = 0;

            if (mod === 1) {
                digit = 9;
            } else if (mod !== 0) {
                digit = 11 - mod;
            }

            return digit === parseInt(plainCuit.charAt(plainCuit.length - 1));
        }
        return false;
    }
    
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, controller) {
            //DOM -> model validation
            controller.$parsers.unshift(function(value) {
                var valid = false;
                var plainCuit;
                if (angular.isDefined(value)) {
                    plainCuit = value.replace(/\D/g, '');
                    valid = isValid(plainCuit);
                }
                controller.$setValidity('cuitMustBeReal', valid);
                return plainCuit;
            });

            //model -> DOM validation
            controller.$formatters.unshift(function(value) {
                if (angular.isDefined(value) && isFinite(value) && value !== null) {
                    var plainCuit = value.replace(/\D/g, '');
                    controller.$setValidity('cuitMustBeReal', isValid(plainCuit));
                    return plainCuit;
                }
                return value;
            });
        }
    };
});