(function () {
  'use strict';

  angular.module('performance-evaluation')

    .controller('SearchEvaluationController', ['$scope', 'PerformanceEvaluationService', 'PeriodService', '$state', '$rootScope', '$timeout', '$translate', '$uibModal', '$window', function ($scope, PerformanceEvaluationService, PeriodService, $state, $rootScope, $timeout, $translate, $uibModal, $window) {

      $scope.generalPerformances = [
        'Supera las expectativas',
        'Alcanza las expectativas',
        'Por debajo de las expectativas',
        'No alcanza las expectativas',
      ];

      $scope.states = [
        'PENDING',
        'IN_PROGRESS',
        'FINISHED',
        'EXCEPTUATED'
      ];

      $scope.selected_general_performance = null;
      $scope.selected_period = null;
      $scope.selected_state = 'FINISHED';
      $scope.selected_collaborator_fullname = null;
      $scope.selected_leader_fullname = null;

      $scope.evaluations = [];

      $scope.searchParams = {};

      $scope.init = function () {
        $scope.getPeriods();
        $scope.getEvaluationsFiltered(1);

      };

      $scope.makeEvent = function (evaluation_id) {
        var modal = $state.get('exclude-evaluation');
        modal.resolve.modalParams = function () {
          return {
              evaluation_id: evaluation_id
          };
        };
        modal.windowClass = 'modal-zindex';
        modal.size = 'lg';
        var modalInstance = $uibModal.open(modal);

        modalInstance.result.then(function () {
          PerformanceEvaluationService.GetEvaluations($scope.searchParams).then(function (success) {
            $timeout(function () { $scope.$broadcast('loadPagination', success.data.paging); });
            $scope.evaluations = success.data.result;
            console.log($scope.evaluations);
  
          }, function (error) {
            console.log('error fetching evaluations', error);
            swal('No es posible mostrar las evaluaciones', error.data.cause, 'error');
          });
        }, function () {
            //when error
        });  
    }

      $scope.getPeriods = function () {
        PeriodService.GetPeriods({}).then(function success(response) {
          $scope.periods = response.data.result;
          $scope.selected_period = $scope.periods.find(p => p.status === 'RUNNING');
          $scope.selected_period = $scope.selected_period === undefined ? '' : $scope.selected_period
        }, function error(response) {
          console.error('### error fetching periods ', response);
        });
      }

      $scope.getEvaluationsFiltered = function (currentPage) {
        let params = {};
        if ($scope.selected_period && $scope.selected_period != '') {
          params['period'] = $scope.selected_period.id;
        }
        if ($scope.selected_state && $scope.selected_state != '') {
          params['state'] = $scope.selected_state;
        }
        if ($scope.selected_general_performance && $scope.selected_general_performance != '') {
          params['generalPerformance'] = $scope.selected_general_performance;
        }
        if ($scope.selected_collaborator_fullname && $scope.selected_collaborator_fullname != '') {
          params['collaboratorFullname'] = $scope.selected_collaborator_fullname;
        }
        if ($scope.selected_leader_fullname && $scope.selected_leader_fullname != '') {
          params['leaderFullname'] = $scope.selected_leader_fullname;
        }
        var pageable = {};
        pageable['page'] = currentPage;
        pageable['limit'] = 10;
        params.pageable = pageable;
        $scope.searchParams = params;
        PerformanceEvaluationService.GetEvaluations(params).then(function (success) {
          $timeout(function () { $scope.$broadcast('loadPagination', success.data.paging); });
          $scope.evaluations = success.data.result;
          console.log($scope.evaluations);

        }, function (error) {
          console.log('error fetching evaluations', error);
          swal('No es posible mostrar las evaluaciones', error.data.cause, 'error');
        });
      };

      $scope.print = function (evaluation) {
        PerformanceEvaluationService.DocumentPrint(evaluation.id).then( function success(response) {
            var newBlob = new Blob([response.data], {type: 'application/pdf'});
            const data = URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download='HB-' + evaluation.evaluateeFullname.replace(' ', '_') + '-' + evaluation.period.replace(' ', '_') + '.pdf';
            link.click();
            setTimeout(function(){
                URL.revokeObjectURL(data);
            }, 1000);
            $window.open(trash, '_blank');
        }, function error(error) {
            swal('No es posible procesar la solicitud', error.data.cause, 'error');
        });
    };

      $scope.enable = function (evaluationId) {
        swal({
          title: 'Habilitar evaluación',
          text: 'La evaluación volverá a estar disponible para el líder y el colaborador.',
          type: 'info',
          showCancelButton: true,
          confirmButtonClass: 'btn-info',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            PerformanceEvaluationService.EnableEvaluation(evaluationId).then(function success(response) {
              $scope.getEvaluationsFiltered(1);
              swal('Evaluación habilitada', '', 'success');
            }, function error(error) {
              let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
              swal('No ha sido posible habilitar la evaluación.', errorText, 'error');
            });
          }
        });
      };

      $scope.includeEvaluation = function (evaluationId){
        swal({
          title: 'Incorporar evaluación',
          text: 'La evaluación será incorporada al período',
          type: 'info',
          showCancelButton: true,
          confirmButtonClass: 'btn-info',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            PerformanceEvaluationService.IncludePerformanceEvaluation(evaluationId).then(function success(response) {
              $scope.getEvaluationsFiltered(1);
              swal('Evaluación incorporada', '', 'success');
            }, function error(error) {
              let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
              swal('No ha sido posible incorporar la evaluación.', errorText, 'error');
            });
          }
        });
      };

      $scope.watchEvaluation = function (evaluation) {
        if(evaluation.type === 'LEAD_LEAD'){

          let param = {evaluationId: evaluation.id, disabled: true, adminWatching: true};
          $state.go('ltl-fourth-step', param);
        }
        else if (evaluation.type === 'LEAD_COLLABORATOR'){
            let param = {evaluationId: evaluation.id, disabled: true, adminWatching: true};
            $state.go('ltc-third-step', param);
      }
    };

      $scope.init();


    }]);

})();