angular.module('paginationModule', [])
  .directive('pagination', [function () {
    return {
      restrict: 'E',
      scope:{
        changePageCallback: '&',
      },
      templateUrl: 'shared/pagination/pagination.html',
      controller: [
        '$scope',
        '$http',
        '$element',
        '$attrs',
        function ($scope, $http, $element, $attrs) {
          $scope.maxSize = $attrs.maxSize;
          $scope.totalItems = 0;
          $scope.currentPage = 1;
          $scope.pagingVisibility = 'visibility:hidden';

          $scope.changePage = function () {
            $scope.changePageCallback({params: this.currentPage});
          };

          $scope.$on('loadPagination', function (event, paging) {
            $scope.pagingVisibility = 'visibility:visible';
            $scope.currentPage = paging.actualPage;
            $scope.maxSize = paging.limit;
            $scope.itemsPerPage = paging.limit;
            $scope.totalItems = paging.totalItems;
            if ($scope.totalItems === 0) {
              $scope.pagingVisibility = 'visibility:hidden';
            }

          });
        }]
    }}]);