(function () {
    'use strict';

angular.module('performance-evaluation')
.controller('ExcludeEvaluationCtrl', [ '$scope', '$uibModalInstance', 'modalParams', '$timeout', 'PerformanceEvaluationService', function($scope, $uibModalInstance, modalParams, $timeout, PerformanceEvaluationService) {
	
	$scope.motives = ['LICENSE', 'OTHER'];
	
	$scope.error = {errorCode: undefined};

	$scope.clear = function () {
	};

	$scope.init = function () {	
	};

	$scope.confirm = function() {

		$scope.submitted = true;
		if($scope.exclusionForm.$invalid){
			return;
		}

		let body = {
			'evaluationId': modalParams.evaluation_id,
			'exclusionReasonDto': $scope.exclusion_description,
			'exclusionTypeDto': $scope.exclusion_type
		};
		PerformanceEvaluationService.ExcludePerformanceEvaluation(body).then(function success(){
			$scope.clear();
			$uibModalInstance.close();
			swal('La evaluación fue exceptuada correctamente.', '', 'success');
		}, function error(error) {
			swal('Error al intentar exceptuar la evalaución.', error.data.cause, 'error');
		});
		
	};

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};

	$scope.init();	
	
}]);

})();