'use strict';

angular.module('import-team', ['angular-echarts'])

/**
 * ltl : Lead To Lead
 * ltc : Lead To Collaborator
 */
.config([ '$stateProvider', function($stateProvider) {
    $stateProvider.state('import-team', {
        url: '/import-team',
        templateUrl: 'components/import_team/import-team.html',
        controller: 'ImportTeamController'
    })
    .state('modal-result', { 
      url: '/modal-result',
      templateUrl: 'components/import_team/modal-result.html',
          controller: 'ModalResultController',
          resolve: {}
    });
}])

.service('ImportTeamService', [
	'$rootScope',
  '$http',
  'restApiUrl',
    function($rootScope, $http, restApiUrl) {
      var service = {}; 

      service.ProcessFile = ProcessFile

      function ProcessFile () {
        return $http.get(`${restApiUrl}/users/process`);
      }
      return service;
    }]
)

.controller('ImportTeamController', ['$scope', 'ImportTeamService', 'Upload', 'restApiUrl', '$rootScope', '$state', '$http', '$uibModal', function($scope, ImportTeamService, Upload, restApiUrl, $rootScope, $state, $http, $uibModal) {
  $scope.init = function () {
    $scope.f = null;
    $scope.errFile = {};
    $scope.status = {};
    $scope.result = {};
  }

  $scope.uploadFiles = function(file, errFiles) {
    $scope.f = file;
    $scope.errFile = errFiles && errFiles[0];
    if (file) {
      
        file.upload = Upload.upload({ 
          url:`${restApiUrl}/users/upload`,
          data: {file: file}
        });

        file.upload.then(function (response) {
          console.log('OK', response);
        }, function (response) {
          console.log('Error', response);
        }, function (evt) {
          console.log('Evt', evt);
        });
    }   
  };

  $scope.process = function () {
    ImportTeamService.ProcessFile().then(function success(response) {
      var modal = $state.get('modal-result');
		
      modal.resolve.modalParams = function() {
        return {
          results: response.data
        };
      };
      
      modal.windowClass = 'modal-zindex';
      modal.size = 'xlg';
      var modalInstance = $uibModal.open(modal);
      
      modalInstance.result.then(function() {
      }, function () {
      });
      $scope.result = response.data;
    }, function error(response) {
      console.error('process error: ', response);
    })
  }

  $scope.init();

}]);
