(function () {
  'use strict';

angular.module('initialization', [])


/**
*  
* Initialization Service 
*
*/
.service('InitService', [ '$rootScope', '$state', '$http', 'restApiUrl', 'PerformanceEvaluationService',

function($rootScope, $state, $http, restApiUrl, PerformanceEvaluationService) {

  var service = {};

  service.Init = Init;

  function Init () {
    return Promise.all(
      [
        PerformanceEvaluationService.GetPerformanceEvaluationMe(),
        PerformanceEvaluationService.GetPendingPerformanceEvaluations()
      ]
    );
  }

  return service;


}])

})();