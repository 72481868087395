'use strict';

angular.module('FrenchLoanCalculatorService', [])
.service('FrenchLoanCalculator', function () {
    this.getPeriodicPaymentAmount = function (loanAmount, nominalAnnualRate, totalNumberOfPayment, vatTaxRate) { 
        if (totalNumberOfPayment == 0) {
            return 0;
        }
        
        if (!nominalAnnualRate) {
            return parseFloat((loanAmount / totalNumberOfPayment).toFixed(2));
		}
		
		if (vatTaxRate != null) {
			nominalAnnualRate = parseFloat(nominalAnnualRate * (1 + vatTaxRate));
		}
		
		var periodicInterestRate = parseFloat((nominalAnnualRate / 12).toFixed(6)); //Monthly payment
	       
		var partialTerm = 1 + periodicInterestRate;
		var partialPower = Math.pow(partialTerm, totalNumberOfPayment);
	
		var numerator = partialPower * periodicInterestRate * loanAmount;
		var denominator = partialPower - 1;
				
		return parseFloat((numerator / denominator).toFixed(2));
    };
    
    this.getPeriodicPaymentAmountWithStampTaxByNumeralRate = function (loanAmount, nominalAnnualRate, totalNumberOfPayment, vatTaxRate, stampTaxByNumeralRate) { 
        if (totalNumberOfPayment == 0) {
            return 0;
        }
        
        if (!stampTaxByNumeralRate) {
            return this.getPeriodicPaymentAmount(loanAmount, nominalAnnualRate, totalNumberOfPayment, vatTaxRate);
		}
		
        var paymentAmountWithoutStampTax = this.getPeriodicPaymentAmount(loanAmount, nominalAnnualRate, totalNumberOfPayment, vatTaxRate);
		var totalInterestAmount = this.getTotalInterestOfLoan(loanAmount, totalNumberOfPayment, paymentAmountWithoutStampTax);
		
		var totalStampTaxAmount;
		if (vatTaxRate != null && vatTaxRate !== undefined) {
			totalStampTaxAmount = this.getStampTaxAmountOverNumerals(totalInterestAmount, nominalAnnualRate, stampTaxByNumeralRate);
			totalStampTaxAmount = (totalStampTaxAmount / ( 1 + parseFloat(vatTaxRate))).toFixed(2);
		} else {
			totalStampTaxAmount = this.getStampTaxAmountOverNumerals(totalInterestAmount, nominalAnnualRate, stampTaxByNumeralRate); 
		}

		var averageStampTaxAmount = parseFloat((totalStampTaxAmount / totalNumberOfPayment).toFixed(2));
		
		return parseFloat((paymentAmountWithoutStampTax + averageStampTaxAmount).toFixed(2));
    };
    
    this.getTotalInterestOfLoan = function (loanAmount, totalNumberOfPayment, periodicPaymentAmount) {
        return parseFloat((periodicPaymentAmount * totalNumberOfPayment - loanAmount).toFixed(2));
    };
    
    this.getStampTaxAmountOverNumerals = function (interestAmount, nominalAnnualRate, stampTaxRate) {
        return parseFloat((interestAmount * stampTaxRate / nominalAnnualRate).toFixed(2));
    };
    
    this.getLoanAmount = function(periodicPaymentAmount, nominalAnnualRate, totalNumberOfPayment, vatTaxRate, stampTaxByNumeralRate) {
        if (totalNumberOfPayment == 0) {
            return 0;
        }
        
        if (!nominalAnnualRate) {
			return parseFloat((periodicPaymentAmount * totalNumberOfPayment).toFixed(2));
		}
		
		var periodicInterestRate = (nominalAnnualRate / 12).toFixed(6);
		var periodicInterestRateWithVatTaxRate = 0;
		var periodicstampTaxByNumeralRate = 0;
		
		if (vatTaxRate != null && vatTaxRate !== undefined) {
			var periodicInterestRateWithVatTaxRate = periodicInterestRate * (1 + parseFloat(vatTaxRate));
		}
		
		if (stampTaxByNumeralRate != null && stampTaxByNumeralRate !== undefined) {
			var periodicStampTaxByNumeralRate = parseFloat(periodicInterestRate * stampTaxByNumeralRate);
		}
		
		periodicInterestRate = parseFloat((periodicInterestRateWithVatTaxRate + periodicStampTaxByNumeralRate).toFixed(6));
		
		var partialTerm = 1 + periodicInterestRate;
		var partialPower = Math.pow(partialTerm, totalNumberOfPayment);
	
		var numerator = (partialPower - 1) * periodicPaymentAmount;
		var denominator = partialPower * periodicInterestRate;

		return parseFloat((numerator / denominator).toFixed(2));
    };
    
});