angular.module('app.layout')

.controller('HeaderController', [
                                    '$rootScope',
                                    '$scope',
                                    '$state',
                                    'AuthenticationService',

function($rootScope, $scope,  $state, AuthenticationService) {

    $scope.init = function (){
    }

    $scope.$on('$includeContentLoaded', function() {
        Layout.initHeader(); // init header
		App.initSlimScroll('.scroller');
    });

    $scope.logout = function(){	

        AuthenticationService.Logout(function(result, data) {
            $state.go('login');
        });
    };

    $scope.go_to = function (notification_type) {
    };

    $scope.init();
}]);
