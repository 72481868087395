(function () {
    'use strict';

angular.module('import-team')

.controller('ModalResultController', [ '$scope', '$uibModalInstance', 'modalParams', '$timeout', function($scope, $uibModalInstance, modalParams, $timeout) {
    
    $scope.success = 0;
    $scope.creations = 0;
    $scope.updates = 0;
    $scope.failures = 0;
    $scope.results = modalParams.results;

	$scope.init = function () {
        $scope.success = $scope.results.success;
        $scope.creations = $scope.results.creations;
        $scope.updates = $scope.results.updates;
        $scope.failures = $scope.results.failures;
	};

	$scope.close = function() {
		$uibModalInstance.dismiss('cancel');
	};

	$scope.init();	
	
}]);

})();