angular.module('translate', ['pascalprecht.translate'])

.config(['$translateProvider', function($translateProvider) {

    //The files used in internationalization are in:
    $translateProvider.useStaticFilesLoader({
        prefix: 'shared/i18n/',
        suffix: '.json'
    });

    //language default
    $translateProvider.preferredLanguage('es_AR');
    
    $translateProvider.useSanitizeValueStrategy('escape');
    
	$translateProvider.useMissingTranslationHandlerLog();    
}])

.controller('ctrlTranslate', ['$scope', '$translate', function($scope, $translate) {

  $scope.setLang = function(langKey) {
    // You can change the language during runtime
    $translate.use(langKey);
  };

 }]);