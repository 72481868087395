angular.module('gdhSlider', [])

.directive('gdhSlider', function () {
    return {
      template: '<div ya-no-ui-slider=\'sliderOptions\' ya-no-ui-slider-events=\'handlers\' ></div>',
      restrict: 'E',
      scope: {
        options: '&',
        onUpdate: '&',
        onChange: '&',
        onSet: '&'
      },
      controller: ['$scope', function($scope) {

        $scope.handlers = {
          update: function(values, handle, unencoded) {
            $scope.onUpdate({ value: values[0] });
          },
          slide: function(values, handle, unencoded) {
             //TODO implementar slide onSlide
          },
          set: function(values, handle, unencoded) {
            $scope.onSet({ values: values });
          },
          change: function(values, handle, unencoded) {
            $scope.onChange({ values: values });
          }
        };

        // noUiSlider Implementation
        $scope.sliderOptions = $scope.options();
      }]
    };
  });