'use strict';

angular.module('defaultInstance', ['bspAngularCrud'])

.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
  
    $stateProvider.state('defaultInstance', { // nombre del estado (no debe repetirse)
	    url: '/defaultInstance', // Url a la que se le va a pegar para ir al estado
	    templateUrl: 'components/default-instance/default-instance.html', // html que se mostrara            
	    controller: 'DefaultInstance'
	});
}])

.controller('DefaultInstance', [ '$scope', 'bspCrudGridDataFactory', function($scope, bspCrudGridDataFactory) {

	    // Variables para collapsar los filtros.
	    $scope.collapsed = true;
	    $scope.collapseClick = function() {
	        $scope.collapsed = !$scope.collapsed;
	    };
	    $scope.isCollapse = function() {
	        return $scope.collapsed;
	    };
    
	  	$scope.init = function() {
			$scope.showGrid = false;
	    };

		var actionButtons = '<div class="btn-group">' + 
			 '<button ng-click="grid.appScope.prepareForm(row.entity,3)" type="button" class="btn btn-success btn-xs btn-space" ' +
		     '	aria-label="{{\'load.BUTTON.VIEW\' | translate}}" ' +
		     '  data-toggle="modal" data-target="#crudFormTpl" ' +
		     '  title="{{\'load.BUTTON.VIEW\' | translate}}" translate> ' +
		     '	<span class="glyphicon glyphicon-eye-open" aria-hidden="true"/> ' +
		     '</button>' +
		     '<button ng-click="grid.appScope.prepareForm(row.entity,1)" type="button" class="btn btn-info btn-xs btn-space" ' + 
			 '	aria-label="{{\'load.BUTTON.EDIT\' | translate}}" ' +
			 '  data-toggle="modal" data-target="#crudFormTpl" ' +
			 '  title="{{\'load.BUTTON.EDIT\' | translate}}" translate>' +
			 '	<span class="glyphicon glyphicon-edit" aria-hidden="true"/> ' +
			 '</button>' +
		 '</div>';
	    
	       			
	   $scope.gridOptions = {
	   		enableSorting: false,
	   		enableFiltering: false,
	   		enableColumnResizing: true,
	   		enableGridMenu: true,
	   		exporterMenuPdf: false,
	   		exporterMenuExcel: false,
	   		columnDefs: [
	             { field: 'description', displayName: 'Descripción',enableHiding: false},
	             { field: 'value', displayName: 'Valor', enableHiding: false},
	             { name: 'actions', displayName: 'Acciones', cellTemplate: actionButtons, enableColumnMenu: false, 
	           	  enableHiding: false, enableSorting: false, enableFiltering: false, enableColumnResizing: false, 
	           	  width:120, cellClass:'ui-grid-cell-actions' }
	           ]
	   };
	   
	   $scope.customSuccessQueryCallback = function(data) {
			$scope.gridOptions.data = data.result;
			if (typeof data.result !== 'undefined' && data.result.length > 0) {
				$scope.showGrid = true;
			} else {
				$scope.showGrid = false;
			}
	   };
	   
	   $scope.init();	
	
}]);