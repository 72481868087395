(function () {
    'use strict';

angular.module('notifications', [])


/**
 *  
 * Notifications Service 
 * 
 * 
 */
.service('NotificationsService', [ '$rootScope', '$state', '$http', 'restApiUrl',

function($rootScope, $state, $http, restApiUrl) {


    var service = {};
   
    service.GetNotifications = GetNotifications;

    function GetNotifications () {
        return $http.get(`${restApiUrl}/notifications`);
    }

    return service;

}]);

})();