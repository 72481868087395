'use strict';


angular.module('app.shared', [	'configService',
								'app.layout',		
								'translate',
								'searchEntity',
								'validateCbu',
								'validateCuit',
								'authentication',
								'notifications',
								'users',
								'events',
								'periods',
								'filters',
								'gdhSlider',
								'util',
								'initialization',
								'paginationModule',
								'gdhBarChart'
							]); 