angular.module('app.layout')

/* Setup Layout Part - Sidebar */
.controller('SidebarController', ['$state', '$scope', '$uibModal', '$localStorage' , function($state, $scope, $uibModal, $localStorage) {
    $scope.$on('$includeContentLoaded', function() {
        Layout.initSidebar($state); // init sidebar
    });
    $scope.init = function () {
        // PerformanceEvaluationService.GetPendingPerformanceEvaluations().then(function success(response){
        //     $scope.pending_performance_evaluations = response.data.result;
        // }, function error(response){
        //     console.log('error fetching pending evaluations', response);
        // })
        // PerformanceEvaluationService.GetPerformanceEvaluationMe().then(function success(response){
        //     if(response.data.result.length > 0 && response.data.result[0].state !== 'FINISHED' && response.data.result[0].state !== 'COMPLETED'){
        //         $scope.my_evaluation = response.data.result[0];
        //     }
        // })
    }

    $scope.$on('refresh', function () {
        $scope.init();
    });

    $scope.makeEvent = function () {

        var modal = $localStorage.loggedUser.isLeader ? $state.get('finish-event-leader') : $state.get('finish-event-collab');

        var whoMakeEvent = $localStorage.loggedUser.isLeader ? 'LEADER' : 'COLLABORATOR';

        modal.resolve.modalParams = function () {
            return {
                whoMakeEvent: whoMakeEvent,
                calledFrom: 'MAIN_MENU'
            };
        };

        modal.windowClass = 'modal-zindex';
        modal.size = 'lg';
        var modalInstance = $uibModal.open(modal);

        modalInstance.result.then(function () {
            //When ok
        }, function () {
            //when error
        });
    }    

    $scope.init();
}])

.factory(
        'SidebarService',
        [
                '$http',
                'restApiUrl',
                function($http, restApiUrl) {
                    return {
                        menu : function() {
                        	return $http.get(restApiUrl + '/menu');
                        }
                };
}]);
