(function () {
    'use strict';

angular.module('dashboard')

.controller('FinishEventCollabCtrl', [ '$scope', '$uibModalInstance', 'modalParams', 'EventsService', 'UsersService', '$timeout', function($scope, $uibModalInstance, modalParams, EventsService, UsersService, $timeout) {
	
	$scope.userMe = null;
	$scope.score = undefined;
	$scope.selected_collaborator = {};
	$scope.collaborators = [];
	$scope.description = '';
	$scope.shared = false;
	$scope.date = moment();
	$scope.commentTo = 'LEADER';

	$scope.clear = function () {
		$scope.score = '';
		$scope.selected_collaborator = {};
		$scope.description = '';
	};


	$scope.init = function () {
		UsersService.GetMe().then(function success(response) {
			console.log('response', response);
			$scope.userMe = response.data;
		}, function error(response) {
			swal('Colaborador no encontrado', `El email ${$localStorage.loggedUser.email} no se encuentra registrado. Por favor póngase en contacto con el administrador.`, 'warning');
		});		
	};

	$scope.confirm = function() {
		
		$scope.submitted = true;

		if($scope.eventForm.$invalid){
			throw new Error('Formulario invalido');	
		}

		let body = {
			'description': $scope.description,
			'score': $scope.score,
			'shared': $scope.shared,
			'eventTime': moment($scope.date).format()
		};
		
		EventsService.PostCollabOrMyselfEvent(body, $scope.commentTo).then(function success(response){
			$scope.clear();
			$uibModalInstance.close();
			swal('La nota fue guardada correctamente en la bitácora.', '', 'success');
		}, function error(response) {
			swal('Algo salió mal. La nota no pudo ser guardada en  la bitácora.', '', 'error');
		});

	};


	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	};
	
	$scope.selectScore = function (score) {
		if($scope.score === score){
			$scope.score = '';
		}
		else {
			$scope.score = score;
		}
	}


	$scope.init();	
	
}]);

})();